import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import { MetronicI18nProvider } from 'metronic/i18n/Metronici18n';
import { ThemeModeProvider } from 'metronic/partials/layout/theme-mode/ThemeModeProvider';
import { setupStore } from 'app/redux/store';
import { AppRoutes } from 'app/routing/AppRoutes';

import './metronic/assets/sass/plugins.scss';
import './metronic/assets/sass/style.scss';
import './metronic/assets/sass/style.react.scss';


const container = document.getElementById('root');

if (container) {
  createRoot(container).render(
    <Provider store={setupStore()}>
      <MetronicI18nProvider>
        <ThemeModeProvider>
          <AppRoutes />
        </ThemeModeProvider>
      </MetronicI18nProvider>
    </Provider>
  );
}
