import { FC } from 'react';
import clsx from 'clsx';

import { HeaderUserMenu, ThemeModeSwitcher } from 'metronic/partials/index';
import { NotificationMenu } from 'metronic/layout/components/header/notification-menu/NotificationMenu';
import { toAbsoluteUrl } from '../../../helpers';
import { ShellMenu } from './shell-menu/ShellMenu';
import { useAppSelector } from 'app/hooks/redux';

const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
  toolbarButtonHeightClass = 'btn-active-light-primary btn-custom w-30px h-30px w-md-40px h-md-40p',
  toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px',
  toolbarButtonIconSizeClass = 'svg-icon-1';

export const Topbar: FC = () => {
  const currentUser = useAppSelector(state => state.user.currentUser);

  if (!currentUser) {
    return (
      <div className="d-flex align-items-stretch flex-shrink-0">
        <div className="topbar d-flex align-items-stretch flex-shrink-0">
          <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
            <ThemeModeSwitcher toggleBtnClass={toolbarButtonHeightClass}/>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="d-flex align-items-stretch flex-shrink-0">
      <div className="topbar d-flex align-items-stretch flex-shrink-0">

        <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
          <ShellMenu
            wrapperClsx={clsx(
              'btn btn-icon btn-active-light-primary btn-custom position-relative',
              toolbarButtonHeightClass
            )}
            svgClsx={toolbarButtonIconSizeClass}
          />
        </div>

        <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
          <NotificationMenu
            wrapperClsx={clsx(
              'btn btn-icon btn-active-light-primary btn-custom position-relative',
              toolbarButtonHeightClass
            )}
            svgClsx={toolbarButtonIconSizeClass}
          />
        </div>

        <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
          <ThemeModeSwitcher toggleBtnClass={toolbarButtonHeightClass} />
        </div>

        <div
          className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
          id="kt_header_user_menu_toggle"
        >
          <div
            className={clsx('cursor-pointer symbol', toolbarUserAvatarHeightClass)}
            data-kt-menu-trigger="click"
            data-kt-menu-attach="parent"
            data-kt-menu-placement="bottom-end"
            data-kt-menu-flip="bottom"
          >
            <img
              className="h-30px w-30px rounded"
              src={toAbsoluteUrl('/media/avatars/blank.png')}
              alt="metronic"
            />
          </div>
          <HeaderUserMenu />
        </div>
      </div>
    </div>
  );
};
