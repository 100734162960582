import { createApi } from '@reduxjs/toolkit/query/react';
import { ISubscription } from 'app/types/subscription.interface';

import { z3xBaseQuery } from 'app/utils/api.utils';

interface ISubscriptionBody {
  page?: number;
  itemsPerPage?: string;
}

type ISubscriptionResponse = ISubscription[]

export const subscriptionsApi= createApi({
  reducerPath: 'subscriptions',
  baseQuery: z3xBaseQuery(),
  endpoints: (builder) => ({
    getAllSubscriptions: builder.query<ISubscriptionResponse, ISubscriptionBody>({
      query: ({ page, itemsPerPage }) => ({
        url: 'subscriptions/',
        params: { page, itemsPerPage }
      })
    }),
  }),
});

export const {
  useGetAllSubscriptionsQuery
} = subscriptionsApi;