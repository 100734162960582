import { FC } from 'react';
import { Link } from 'react-router-dom';

import { useLazyLogoutQuery } from 'app/redux/api/auth/auth.api';
import { useAppSelector } from 'app/hooks/redux';
import { toAbsoluteUrl } from 'metronic/helpers';
import { Languages } from './Languages';

export const HeaderUserMenu: FC = () => {
  const { currentUser } = useAppSelector(state => state.user);
  const [sendLogoutRequest] = useLazyLogoutQuery();

  const handleLogout = async () => {
    await sendLogoutRequest();
    window.location.reload();
  };

  return (
    <div
      className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-semibold py-4 fs-6 w-275px"
      data-kt-menu="true"
    >
      <div className="menu-item px-3">
        <div className="menu-content d-flex align-items-center px-3">
          <div className="symbol symbol-50px me-5">
            <img alt="Logo" src={toAbsoluteUrl('/media/avatars/blank.png')} />
          </div>

          <div className="d-flex flex-column overflow-hidden">
            <div className="fw-bold d-flex align-items-center fs-5 text-truncate">
              {currentUser?.profile.name}
            </div>
            <span className="fw-semibold text-muted text-hover-primary fs-7 text-truncate">
              {currentUser?.email}
            </span>
          </div>
        </div>
      </div>

      <div className="separator my-2"></div>

      <div className="menu-item px-5">
        <Link to="/account/settings" className="menu-link px-5">
					Account Settings
        </Link>
      </div>

      <Languages />

      <div className="menu-item px-5">
        <a onClick={handleLogout} className="menu-link px-5">
					Sign Out
        </a>
      </div>
    </div>
  );
};