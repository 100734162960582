import { FC } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router';

import { KTCardSpinner, KTSVG, toAbsoluteUrl } from 'metronic/helpers/index';
import { useGetHeaderNotificationsQuery } from 'app/redux/api/notification/notification.api';
import { NotificationReadIndicator } from 'app/components/shared/notification/NotificationReadIndicator';
import { NotificationLocationState } from 'app/types/notifications.interface';

interface NotificationMenuListProps {
  totalUnreadNotifications: number | undefined
}

export const NotificationMenuList: FC<NotificationMenuListProps> = ({ totalUnreadNotifications }) => {
  const navigate = useNavigate();

  const { data, isLoading } = useGetHeaderNotificationsQuery();

  const handleOpenNotification = (id: number) => {
    const state: NotificationLocationState = { id };

    navigate('/notifications', { state, replace: true });
  };

  const areNotifications = !!(data && data.total);

  return (
    <div
      className="menu menu-sub menu-sub-dropdown menu-column w-350px w-lg-375px"
      data-kt-menu="true"
    >
      <div
        className="d-flex flex-column bgi-no-repeat rounded-top"
        style={{ backgroundImage: `url('${toAbsoluteUrl('/media/misc/menu-header-bg.jpg')}')` }}
      >
        <h3 className="text-white fw-semibold px-9 mt-10 mb-6">
          Latest notifications{' '}
          {!!totalUnreadNotifications && <span className="fs-8 opacity-75 ps-3">Total unread - {totalUnreadNotifications}</span>}
        </h3>
      </div>

      {(!areNotifications && !isLoading) && (
        <div className="p-3 ps-8 my-5 text-dark fw-bold">
          You haven't notifications.
        </div>
      )}

      {areNotifications && (
        <>
          <div className="scroll-y mh-325px my-5 px-8">
            {isLoading && <KTCardSpinner />}

            {data.notifications.map((item, index) => (
              <div key={`not${index}`} className="d-flex flex-stack py-4 px-2 bg-hover-light rounded-1">
                <div className="d-flex justify-content-between align-items-center w-100 gap-2">
                  <span
                    className="w-100 text-gray-800 fw-semibold text-truncate cursor-pointer"
                    onClick={() => handleOpenNotification(item.id)}
                  >
                    {item.message}
                  </span>
                  <NotificationReadIndicator id={item.id} readed={item.readed}/>
                </div>
              </div>
            ))}
          </div>

          <div className="py-3 text-center border-top">
            <Link
              to="/notifications"
              className="btn btn-color-gray-600 btn-active-color-primary"
            >
              View All ({data.total}) <KTSVG path="/media/icons/duotune/arrows/arr064.svg" className="svg-icon-5" />
            </Link>
          </div>
        </>
      )}
    </div>
  );
};