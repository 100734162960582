import { FC, useEffect } from 'react';
import { useStore } from 'react-redux';

import { useAppDispatch, useAppSelector } from 'app/hooks/redux';
import { useCheckSoftwareMutation } from 'app/redux/api/shell/shell.api';
import { setShellStatus } from 'app/redux/slices/shell/shell.slice';
import { WithChildren } from 'app/types/withChildren';
import { RootState } from 'app/redux/store';

const STATUS_INTERVAL_REQUEST = 10000;

export const PollingShellStatus: FC<WithChildren> = ({ children }) => {
  const dispatch = useAppDispatch();
  const store = useStore<RootState>();

  const { currentUser } = useAppSelector(state => state.user);

  const [requestCheckSoftware] = useCheckSoftwareMutation();

  const fetchCheckSoftware = () => {
    const { status } = store.getState().shell;
    requestCheckSoftware().unwrap()
      .then(data => {
        if (!data.isError && status !== 'connected') {
          dispatch(setShellStatus('connected'));
          return;
        }
        if (data.isError && status !== 'connected') {
          dispatch(setShellStatus('no connection'));
          return;
        }
      })
      .catch(() => {
        if (status !== 'no connection') {
          dispatch(setShellStatus('no connection'));
        }
      });
  };

  useEffect(() => {
    if (!currentUser) {
      return;
    }

    fetchCheckSoftware();
    const interval = setInterval(() => fetchCheckSoftware(), STATUS_INTERVAL_REQUEST);
    return () => clearInterval(interval);
  }, [currentUser]);

  return (
    <>
      {children}
    </>
  );
};