import { createApi } from '@reduxjs/toolkit/query/react';

import { z3xBaseQuery } from 'app/utils/api.utils';

interface IUserCrdSubscriptionCreate {
    crdId: number;
    ruleId: number;
}

interface IUserCrdSubscriptionGet {
  crdId?: number;
}

interface IUserCrdSubscriptionCreateResponse {
  subscriptionName: string;
  expirationDate: string;
  new: false;
}

interface IUserCrdSubscription {
  subscriptionName: string;
  expirationDate: string;
  crdId: number;
  ruleId: number;
  new: boolean;
  valid: boolean;
}

type ITransformedUserCrdSubscriptionResponse = IUserCrdSubscription[]


export const userCrdSubscriptionApi = createApi({
  reducerPath: 'userCrdSubscription',
  baseQuery: z3xBaseQuery(),
  endpoints: (builder) => ({
    create: builder.mutation<IUserCrdSubscriptionCreateResponse, IUserCrdSubscriptionCreate>({
      query: (body) => ({
        url: '/user-crd-subscriptions/create',
        method: 'POST',
        body
      }),
    }),
    getUserCrdSubscription: builder.query<ITransformedUserCrdSubscriptionResponse, IUserCrdSubscriptionGet>({
      query: ({ crdId }) => ({
        url: 'user-crd-subscriptions/' + crdId }),
    })
  }),
});

export const {
  useCreateMutation,
  useGetUserCrdSubscriptionQuery,
} = userCrdSubscriptionApi;