import { createApi } from '@reduxjs/toolkit/query/react';

import { SocialAuthDataType } from 'app/types/auth.interface';
import { z3xBaseQuery } from 'app/utils/api.utils';

type ISocialAuthArgs = SocialAuthDataType & { email?: string, urlTo?: string };

interface ISocialAuthDeleteArgs {
	id: number;
}

export const userSocialsApi = createApi({
  reducerPath: 'userSocialsApi',
  baseQuery: z3xBaseQuery(),
  endpoints: (builder) => ({
    socialAuth: builder.mutation<null, ISocialAuthArgs>({
      query: (body) => ({
        url: '/social-auth',
        method: 'POST',
        body
      })
    }),
    socialAuthAdd: builder.mutation<null, SocialAuthDataType>({
      query: (body) => ({
        url: '/social-auth/add',
        method: 'POST',
        body
      })
    }),
    socialAuthDelete: builder.mutation<null, ISocialAuthDeleteArgs>({
      query: ({ id }) => ({
        url: `/social-auth/delete/${id}`,
        method: 'DELETE',
      })
    })
  }),
});

export const {
  useSocialAuthMutation,
  useSocialAuthAddMutation,
  useSocialAuthDeleteMutation
} = userSocialsApi;