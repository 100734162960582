import { FC, useEffect } from 'react';
import { useLocation } from 'react-router';

import { useGetNotificationsToolbarContext } from 'app/modules/notifications/components/_context';
import { useReadNotificationsMutation } from 'app/redux/api/notification/notification.api';
import { NotificationLocationState } from 'app/types/notifications.interface';

interface NotificationReadIndicatorCellProps {
  id: number
	readed: boolean
  successCallback?: () => void
}

export const NotificationReadIndicator: FC<NotificationReadIndicatorCellProps> = ({ id, readed, successCallback }) => {
  const locationState = useLocation().state as NotificationLocationState;

  const [requestReadNotifications, { error, reset }] = useReadNotificationsMutation();

  const { onReadItem } = useGetNotificationsToolbarContext();
  
  useEffect(() => {
    if (error) {
      const timeout = setTimeout(() => reset(), 3000);
      return () => clearTimeout(timeout);
    }
  }, [error]);
  
  useEffect(() => {
    if (locationState?.id === id) {
      handleReadNotification();
    }
  }, [locationState]);
  
  const handleReadNotification = () => {
    requestReadNotifications([id]).unwrap()
      .then(() => {
        if (successCallback) {
          successCallback();
        }
        onReadItem(id);
      })
      .catch(() => {});
  };
  
  if (readed) {
    return null;
  }

  return (
    <span
      className={`bullet bullet-dot bg-${!error ? 'warning' : 'danger animation-blink'} h-8px w-8px cursor-pointer`}
      onClick={handleReadNotification}
    />
  );
};