import * as Yup from 'yup';

export const registrationSchema = Yup.object().shape({
  username: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Username is required'),
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  plainPassword: Yup.string()
    .required('Password is required')
    .min(6, 'Minimum 6 symbols')
    .max(50, 'Maximum 50 symbols')
    .matches(/^(?=.*[a-zA-Z])/, 'At least one letter')
    .matches(/^(?=.*[0-9])(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])/,
      'At least one number and one special character'
    ),
  confirmPassword: Yup.string()
    .required('Confirm password is required')
    .oneOf([Yup.ref('plainPassword'), null], 'Password and Confirm Password must match'),
  acceptTerms: Yup.bool().required('You must accept the terms and conditions'),
});