import { useState } from 'react';
import { useFormik } from 'formik';
import { loginAuthenticationSchema } from 'app/modules/auth/login/loginAuthentication/loginAuthentication.schema';
import clsx from 'clsx';

import { useVerifyCodeMutation } from 'app/redux/api/auth/auth.api';
import { useLazyVerifyAddCodeQuery } from 'app/redux/api/auth/auth.api';
import { useAppSelector } from 'app/hooks/redux';
import { useDispatch } from 'react-redux';
import { setHash2fa } from 'app/redux/slices/user/user.slice';

const initialValues = {
  confirmationcode: ''
};

export const LoginAuthentication = () => {
  const [sendAuthenticationRequest] = useVerifyCodeMutation();
  const [sendVerifyCodeToEmail] = useLazyVerifyAddCodeQuery();

  const [loading, setLoading] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const dispatch = useDispatch();
  const hash2fa = useAppSelector(state => state.user.hash2fa);

  const handleSendANewCode = async () => {
    try {
      if (hash2fa) {
        const response : any = await sendVerifyCodeToEmail({ hash: hash2fa }).unwrap();
        if (response?.hash) {
          setIsClicked(true);
          dispatch(setHash2fa(response.hash));
        }
      }
    } catch (error: any) {
      console.error(error);
    }
  };


  const formik = useFormik({
    initialValues,
    validationSchema: loginAuthenticationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setLoading(true);
      try {
        if (hash2fa) {
          await sendAuthenticationRequest({ code: values.confirmationcode, hash: hash2fa }).unwrap();
        } else {
          formik.setErrors({
            confirmationcode: 'Something went wrong, please try again.',
          });
          return;
        }
        dispatch(setHash2fa(null));
        window.location.reload();
      } catch (error: any) {
        formik.setErrors({
          confirmationcode: 'Invalid confirmation code', // Set error specifically for confirmationcode field
        });
        setSubmitting(false);
        setLoading(false);
      }
    }
  });

  return (
    <form
      className="form w-100"
      onSubmit={formik.handleSubmit}
      noValidate
      id="kt_login_signin_form"
    >
      <div className="text-center mb-10">
        <h1 className="text-dark mb-3">Check your email and paste code in to Z3X User Portal</h1>
      </div>

      <div className="fv-row">
        <label className="form-label fs-6 fw-bold text-dark">Email authentication code</label>
        <input
          placeholder="confirmation code"
          {...formik.getFieldProps('confirmationcode')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            { 'is-invalid': formik.touched.confirmationcode && formik.errors.confirmationcode },
            {
              'is-valid': formik.touched.confirmationcode && !formik.errors.confirmationcode,
            }
          )}
          type="text"
          name="confirmationcode"
          autoComplete="off"
          maxLength={6}
        />
        { formik.touched.confirmationcode && formik.errors.confirmationcode && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.confirmationcode}</span>
            </div>
          </div>
        )}
        <p
          className={`fs-6 mt-2 ${isClicked ? 'text-muted' : 'text-primary'} ${isClicked ? 'disabled' : 'cursor-pointer'}`}
          onClick={!isClicked ? handleSendANewCode : undefined}
        >
          {isClicked ? 'Code has been sent' : 'Send a New Code'}
        </p>
        <div className="text-center mt-10">
          <button
            type="submit"
            id="kt_sign_in_submit"
            className="btn btn-lg btn-primary w-100 mb-5"
            disabled={formik.isSubmitting || !formik.isValid}
          >
            {!loading && <span className="indicator-label">Continue</span>}
            {loading && (
              <span className="indicator-progress" style={{ display: 'block' }}>
                Please wait...
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </button>
        </div>
      </div>
    </form>
  );
};