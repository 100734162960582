import { createApi } from '@reduxjs/toolkit/query/react';

import { z3xBaseQuery } from 'app/utils/api.utils';
import { HydraApiResponse } from 'app/types/hydraData';

interface IUserCrdLogBody {
  id: string;
  page?: number;
  itemsPerPage?: string;
}

interface IUserCrdLog {
  id: number;
  count: number;
  crdOperation: string;
  date: string;
  type: string;
}

interface ITransformedUserCrdResponse {
  logs: IUserCrdLog[],
  total: number
}


export const userCrdLogApi= createApi({
  reducerPath: 'userCrdLog',
  baseQuery: z3xBaseQuery(),
  endpoints: (builder) => ({
    getById: builder.query<ITransformedUserCrdResponse, IUserCrdLogBody>({
      query: ({ id, page, itemsPerPage }) => ({
        url: 'user-crds/log/' + id,
        params: { page, itemsPerPage, 'order[loginAt]': 'desc' }
      }),
      transformResponse: (response: HydraApiResponse<IUserCrdLog[]>) => {
        return {
          logs: response['hydra:member'],
          total: response['hydra:totalItems']
        };
      }
    }),
  }),
});

export const {
  useGetByIdQuery
} = userCrdLogApi;