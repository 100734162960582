import { FC } from 'react';
import { createPortal } from 'react-dom';
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { SHELL_DOWNLOAD_LINK, SHELL_PROTOCOL } from 'app/utils/shell.utils';
import { useAppDispatch, useAppSelector } from 'app/hooks/redux';
import { setShowLaunchShellModal } from 'app/redux/slices/shell/shell.slice';
import { KTSVG } from 'metronic/helpers';


const modalsRoot = document.getElementById('root-modals') || document.body;

export const LaunchShellModal: FC = () => {
  const dispatch = useAppDispatch();
  const show = useAppSelector(state => state.shell.showLaunchShellModal);

  const handleCloseModal = () => {
    dispatch(setShowLaunchShellModal(false));
  };

  const handleOpenShell = () => {
    window.location.href = SHELL_PROTOCOL;
    handleCloseModal();
  };

  const handleDownloadShell = () => {
    window.location.href = SHELL_DOWNLOAD_LINK;
    handleCloseModal();
  };

  return createPortal(
    <Modal
      show={show}
      onHide={handleCloseModal}
      style={{ backgroundColor: 'rgba(0,0,0,.95)' }}
      dialogClassName="modal-dialog modal-dialog-centered mw-100 position-relative"
      contentClassName="bg-transparent text-white position-static align-items-center"
    >
      <div
        data-testid="close-btn"
        className="position-absolute btn btn-sm btn-icon btn-active-color-primary top-0 end-0"
        onClick={handleCloseModal}>
        <KTSVG className="svg-icon-1" path="/media/icons/duotune/arrows/arr061.svg" />
      </div>
      <div className="d-flex flex-column h-100 gap-10 mw-900px flex-md-nowrap flex-wrap px-6 px-lg-0">
        <div
          className="d-flex align-items-center justify-content-center w-100 pb-10 border-gray-600 border-bottom border-bottom-2"
        >
          <span className="text-center fs-2 w-75 w-md-50">
						The Z3X Helper dependency must be installed for Z3X User Portal to work properly
          </span>
        </div>
        <div className="d-flex flex-column flex-md-row gap-10">
          <div className="d-flex flex-column align-items-center gap-3 w-100 text-center">
            <button type="button" className="btn btn-primary" onClick={handleOpenShell}>
              <KTSVG path="/media/icons/duotune/general/gen063.svg" />
              Launch Z3X Helper
            </button>

            <span className="w-75 w-md-100 px-4">
              We will prompt you to open Z3X Helper if it was already installed on your computer
            </span>
          </div>

          <div className="d-flex align-items-center justify-content-center">
            <span className="fs-4">or</span>
          </div>

          <div className="d-flex flex-column align-items-center gap-3 w-100 text-center">
            <button type="button" className="btn btn-primary" onClick={handleDownloadShell}>
              <KTSVG path="/media/icons/duotune/social/soc011.svg" />
              Download Z3X Helper
            </button>

            <span className="w-75 w-md-100 px-4">
              By installing the program, you accept the terms of the{' '}
              <Link to="/policy" className="text-white text-hover-primary cursor-pointer">License Agreement</Link>
            </span>
          </div>
        </div>
      </div>
    </Modal>,
    modalsRoot
  );
};
