import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IShellDownload } from 'app/types/shell.interface';

interface State {
  isRun: boolean;
	status: 'idle' | 'loading' | 'success' | 'error';
  results: IShellDownload[]
}

const initialState: State = {
  isRun: false,
  status: 'idle',
  results: [],
};

export const helperDownloadsSlice = createSlice({
  name: 'helperDownloads',
  initialState,
  reducers: {
    setIsRunHelperDownloads: (state, action: PayloadAction<State['isRun']>) => {
      state.isRun = action.payload;
      state.status = 'idle';
    },
    setStatus: (state, action: PayloadAction<State['status']>) => {
      state.status = action.payload;
    },
    setResults: (state, action: PayloadAction<State['results']>) => {
      state.results = action.payload;
    }
  },
});

export const {
  setIsRunHelperDownloads,
  setStatus,
  setResults,
} = helperDownloadsSlice.actions;

export default helperDownloadsSlice.reducer;
