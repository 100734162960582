import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { LayoutProvider, LayoutSplashScreen } from 'metronic/layout/core';
import { AuthProvider, ShellProvider } from 'app/providers';
import { I18nProvider } from 'metronic/i18n/i18nProvider';
import { MasterInit } from 'metronic/layout/MasterInit';
import CustomToastContainer from 'metronic/layout/CustomToastContainer';

export const App = () => {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <LayoutProvider>
          <AuthProvider>
            <ShellProvider>
              <Outlet />
              <MasterInit />
              <CustomToastContainer />
            </ShellProvider>
          </AuthProvider>
        </LayoutProvider>
      </I18nProvider>
    </Suspense>
  );
};