import { createApi } from '@reduxjs/toolkit/query/react';

import { z3xBaseQuery } from 'app/utils/api.utils';

interface IUnlinkCardFromCrd {
    crdId: number;
    validationString: string;
}

export const unlinkCardFromCrdApi = createApi({
  reducerPath: 'unlinkCardFromCrd',
  baseQuery: z3xBaseQuery(),
  endpoints: (builder) => ({
    unlinkCardFromCrds: builder.mutation<null, IUnlinkCardFromCrd>({
      query: (body) => ({
        url: '/unlink-card-from-crds',
        method: 'POST',
        body
      })
    }),
  }),
});

export const {
  useUnlinkCardFromCrdsMutation,
} = unlinkCardFromCrdApi;