import * as Yup from 'yup';

export const ResetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .required('Password is required')
    .min(6, 'Minimum 6 symbols')
    .max(50, 'Maximum 50 symbols')
    .matches(/^(?=.*[a-zA-Z])/, 'At least one letter')
    .matches(/^(?=.*[0-9])(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])/,
      'At least one number and one special character'
    ),
  confirmPassword: Yup.string()
    .required('Confirm password is required')
    .oneOf([Yup.ref('password'), null], 'Password and Confirm Password must match'),
});