import { FC } from 'react';
import { Link } from 'react-router-dom';

import { toAbsoluteUrl } from 'metronic/helpers/AssetHelpers';
import { KTCard } from 'metronic/helpers/components/KTCard';


export const HomePage: FC = () => {
  return (
	  <KTCard>

		  <div className="card-body text-center">
			  <div className="mt-5 mb-10">
				  <img
					  src={toAbsoluteUrl('/media/logos/z3x-icon-white.svg')}
					  alt="welcome_logo"
					  className="mw-40px theme-dark-show"
				  />
				  <img
					  src={toAbsoluteUrl('/media/logos/z3x-icon.svg')}
					  alt="welcome_logo"
					  className="mw-40px theme-light-show"
				  />
			  </div>

			  <h1 className="fw-bolder text-gray-900 mb-5 mt-5">
				  Welcome to Z3X User Portal
			  </h1>

			  <div className="fw-semibold fs-6 text-gray-500 mb-7">
				  Our team is developing and supporting few solutions for operations with cellphone firmware
				  <br/>
				  that is used by professionals from all over the world.
			  </div>

			  <div className="mb-0">
				  <img
					  src={toAbsoluteUrl('/media/misc/welcome.png')}
					  alt="welcome"
					  className="mw-100 mh-300px theme-light-show"
				  />
				  <img
					  src={toAbsoluteUrl('/media/misc/welcome-dark.png')}
					  alt="welcome_dark"
					  className="mw-100 mh-300px theme-dark-show"
				  />
			  </div>

			  <div>
				  <Link to="/auth" className="btn btn-sm btn-primary">
					  Sign in
				  </Link>
			  </div>

		  </div>
	  </KTCard>
  );
};
