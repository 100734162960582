import { createApi } from '@reduxjs/toolkit/dist/query/react';

import { z3xBaseQuery } from 'app/utils/api.utils';
import {
  IActivateProductArgs,
  IActivateProductResponse,
  IAddDownloadArgs,
  IAddDownloadResponse,
  IAddWebSeedsArgs,
  IBrowseDownloadArgs,
  ICheckSoftwareResponse,
  IGetDownloadFolderResponse,
  IGetDownloadsListArgs,
  IGetDownloadsListResponse,
  IGetListCardsResponse,
  IGetListReadersResponse,
  IGetListSoftwareResponse,
  IGetStatusArgs,
  IGetStatusResponse,
  IGetValidationStringArgs,
  IGetValidationStringResponse,
  IInstallDownloadArgs,
  IInstallDownloadResponse,
  IRemoveDownloadArgs,
  IRemoveDownloadResponse,
  IRunLocalSoftwareArgs,
  IRunLocalSoftwareResponse,
  ISetDownloadFolderResponse,
  IShellDefaultResponse,
  IStartDownloadArgs,
  IStartDownloadResponse,
  IStopDownloadArgs,
  IStopDownloadResponse,
  IUpdateCardFirmwareArgs,
  IUpdateCardFirmwareResponse
} from 'app/types/shell.interface';


export const shellApi = createApi({
  reducerPath: 'shellApi',
  baseQuery: z3xBaseQuery({ url: 'shell', type: 'ld+json', withAutoLogout: false }),
  endpoints: (builder) => ({
    // ----- General -----

    checkSoftware: builder.mutation<ICheckSoftwareResponse, void>({
      query: () => ({
        url: '/general/ping',
        method: 'POST',
      })
    }),
    getDownloadFolder: builder.query<IGetDownloadFolderResponse, void>({
      query: () => ({
        url: '/general/getDownloadFolder',
      })
    }),
    setDownloadFolder: builder.mutation<ISetDownloadFolderResponse, void>({
      query: () => ({
        url: '/general/setDownloadFolder',
        method: 'POST',
      })
    }),

    // ----- Hardware -----

    getListReaders: builder.mutation<IGetListReadersResponse, void>({
      query: () => ({
        url: '/hardware/listReaders',
        method: 'POST',
      })
    }),
    getListCards: builder.mutation<IGetListCardsResponse, void>({
      query: () => ({
        url: '/hardware/listCards',
        method: 'POST',
      })
    }),
    updateCardFirmware: builder.mutation<IUpdateCardFirmwareResponse, IUpdateCardFirmwareArgs>({
      query: ({ readerName }) => ({
        url: '/hardware/updateCardFW',
        method: 'POST',
        body: new URLSearchParams({ readerName })
      })
    }),
    activateProduct: builder.mutation<IActivateProductResponse, IActivateProductArgs>({
      query: ({ productID, readerName }) => ({
        url: '/hardware/activateProduct',
        method: 'POST',
        body: { productID, readerName }
      })
    }),
    getValidationString: builder.mutation<IGetValidationStringResponse, IGetValidationStringArgs>({
      query: ({ readerName }) => ({
        url: '/hardware/getValidation',
        method: 'POST',
        body: new URLSearchParams({ readerName })
      })
    }),

    // ----- Software -----

    getListSoftware: builder.mutation<IGetListSoftwareResponse, void>({
      query: () => ({
        url: '/software/list',
        method: 'POST'
      }),
      transformResponse: (response: IGetListSoftwareResponse) => {
        return {
          ...response,
          data: response.data?.sort((a, b) => a.name > b.name ? 1 : -1)
        };
      }
    }),
    runLocalSoftware: builder.mutation<IRunLocalSoftwareResponse, IRunLocalSoftwareArgs>({
      query: ({ id }) => ({
        url: '/software/run',
        method: 'POST',
        body: new URLSearchParams({ id: String(id) })
      })
    }),

    // ----- Downloads -----

    getDownloadsList: builder.mutation<IGetDownloadsListResponse, IGetDownloadsListArgs>({
      query: ({ softName }) => ({
        url: '/downloads/list',
        method: 'POST',
        body: new URLSearchParams({ softName })
      })
    }),
    addDownload: builder.mutation<IAddDownloadResponse, IAddDownloadArgs>({
      query: ({ url, soft, path }) => ({
        url: '/downloads/add',
        method: 'POST',
        body: new URLSearchParams({ url, soft, path })
      })
    }),
    addWebSeeds: builder.mutation<null, IAddWebSeedsArgs>({
      query: ({ hash, list }) => ({
        url: '/downloads/addWebSeeds',
        method: 'POST',
        body: new URLSearchParams({ hash, list: JSON.stringify(list) })
      })
    }),
    startDownload: builder.mutation<IStartDownloadResponse, IStartDownloadArgs>({
      query: ({ hash }) => ({
        url: '/downloads/start',
        method: 'POST',
        body: new URLSearchParams({ hash })
      })
    }),
    stopDownload: builder.mutation<IStopDownloadResponse, IStopDownloadArgs>({
      query: ({ hash }) => ({
        url: '/downloads/stop',
        method: 'POST',
        body: new URLSearchParams({ hash })
      })
    }),
    startAllDownloads: builder.mutation<IShellDefaultResponse, void>({
      query: () => ({
        url: '/downloads/startAll',
        method: 'POST',
      })
    }),
    stopAllDownloads: builder.mutation<IShellDefaultResponse, void>({
      query: () => ({
        url: '/downloads/stopAll',
        method: 'POST',
      })
    }),
    removeDownload: builder.mutation<IRemoveDownloadResponse, IRemoveDownloadArgs>({
      query: ({ hash, withData }) => ({
        url: '/downloads/remove',
        method: 'POST',
        body: new URLSearchParams({ hash, ...(withData) && { withData } })
      })
    }),
    installDownload: builder.mutation<IInstallDownloadResponse, IInstallDownloadArgs>({
      query: ({ hash, isSoftwareUpdate }) => ({
        url: '/downloads/install',
        method: 'POST',
        body: new URLSearchParams({ hash, ...(isSoftwareUpdate) && { isSoftwareUpdate } })
      })
    }),
    browseDownload: builder.mutation<null, IBrowseDownloadArgs>({
      query: ({ hash }) => ({
        url: '/downloads/browse',
        method: 'POST',
        body: new URLSearchParams({ hash })
      })
    }),

    // ----- Status -----

    getStatus: builder.mutation<IGetStatusResponse, IGetStatusArgs>({
      query: ({ id, allMessages }) => ({
        url: '/status/get',
        method: 'POST',
        body: new URLSearchParams({
          id,
          ...(allMessages) && { allMessages }
        })
      })
    }),
  })
});

export const {
  // ----- General -----

  useCheckSoftwareMutation,
  useLazyGetDownloadFolderQuery,
  useSetDownloadFolderMutation,

  // ----- Hardware -----

  useGetListReadersMutation,
  useGetListCardsMutation,
  useUpdateCardFirmwareMutation,
  useActivateProductMutation,
  useGetValidationStringMutation,

  // ----- Software -----

  useGetListSoftwareMutation,
  useRunLocalSoftwareMutation,

  // ----- Downloads -----

  useGetDownloadsListMutation,
  useAddDownloadMutation,
  useAddWebSeedsMutation,
  useStartDownloadMutation,
  useStopDownloadMutation,
  useStartAllDownloadsMutation,
  useStopAllDownloadsMutation,
  useRemoveDownloadMutation,
  useInstallDownloadMutation,
  useBrowseDownloadMutation,

  // ----- Status -----

  useGetStatusMutation,

} = shellApi;
