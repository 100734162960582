import { FC, useEffect, useState } from 'react';

import {
  useLazyGetDownloadFolderQuery,
  useSetDownloadFolderMutation
} from 'app/redux/api/shell/shell.api';

export const ShellMenuFolder: FC = () => {
  const [folder, setFolder] = useState('');

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const [requestGetDownloadFolder, { isLoading: isGetLoading }] = useLazyGetDownloadFolderQuery();
  const [requestSetDownloadFolder, { isLoading: isSetLoading }] = useSetDownloadFolderMutation();

  const fetchGetDownloadFolder = () => {
    requestGetDownloadFolder().unwrap()
      .then(data => {
        if (!data.isError) {
          setFolder(data.data);
        }
      })
      .catch(() => setError(true))
      .finally(() => setLoading(false));
  };

  const fetchSetDownloadFolder = () => {
    requestSetDownloadFolder().unwrap()
      .then(data => {
        if (!data.isError) {
          fetchGetDownloadFolder();
        }
      })
      .catch(() => setError(true))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchGetDownloadFolder();
  }, []);

  if (loading) {
    return (
      <div className="d-flex justify-content-center">
        <span className="spinner-border h-20px w-20px spinner-border-sm align-middle ms-2" />
      </div>
    );
  }

  if (error) {
    return (
      <div>We cannot load your download folder</div>
    );
  }

  return (
    <div className="d-flex flex-column p-5 py-7">
      {folder ?
        <>
          <span className="px-3">
							Download Folder:
          </span>
          <span className="text-lg-start mt-4">
            {<code>{folder}</code>}
          </span>
        </>
        : <div className="mb-5 px-3">
					Default download folder not selected!
        </div>
      }

      <div className="d-flex align-items-center gap-3 mt-5">
        <span className="px-3">
          {folder ? 'Change' : 'Set'} Download Folder:
        </span>
        <button
          className="btn btn-sm btn-primary px-3 py-1 w-70px"
          onClick={fetchSetDownloadFolder}
          disabled={isGetLoading || isSetLoading}
        >
					Choose
        </button>
      </div>
    </div>
  );
};