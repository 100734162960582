import React from 'react';
import { ToastContainer } from 'react-toastify';
import { useThemeMode } from 'metronic/partials/layout/theme-mode/ThemeModeProvider';
import 'react-toastify/dist/ReactToastify.css';

const CustomToastContainer = () => {
  const { mode } = useThemeMode();

  return (
    <ToastContainer
      theme={mode}
    />
  );
};

export default CustomToastContainer;