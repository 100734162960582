import { createApi } from '@reduxjs/toolkit/dist/query/react';

import { ISupportConversation } from 'app/types/support.interface';
import { HydraApiResponse } from 'app/types/hydraData';
import { z3xBaseQuery } from 'app/utils/api.utils';


interface IGetConversationsArgs {
  page: number;
  itemsPerPage: number;
}

interface IGetConversationsResponse {
  conversations: ISupportConversation[]
	total: number
}

export const conversationApi = createApi({
  reducerPath: 'conversationApi',
  baseQuery: z3xBaseQuery({ type: 'ld+json' }),
  endpoints: (builder) => ({
    getConversations: builder.query<IGetConversationsResponse, IGetConversationsArgs>({
      query: ({ page, itemsPerPage }) => ({
        method: 'GET',
        url: '/conversations',
        params: { page, itemsPerPage }
      }),
      transformResponse: (response: HydraApiResponse<ISupportConversation[]>) => {
        return {
          conversations: response['hydra:member'],
          total: response['hydra:totalItems']
        };
      }
    })
  }),
});

export const {
  useGetConversationsQuery
} = conversationApi;