import { useState } from 'react';
import { Link, Navigate, useSearchParams } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { useFormik } from 'formik';
import clsx from 'clsx';

import { ResetPasswordSchema } from './resetPassword.schema';
import { useResetMutation } from 'app/redux/api/auth/auth.api';

const initialValues = {
  password: '',
  confirmPassword: ''
};

export const ResetPassword = () => {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const [requestReset] = useResetMutation();

  const [loading, setLoading] = useState(false);

  const token = searchParams.get('token');

  const formik = useFormik({
    initialValues,
    validationSchema: ResetPasswordSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      if (!token) {
        return;
      }

      setLoading(true);

      try {
        const data = { token, newPassword: values.password };

        await requestReset(data).unwrap();

        navigate('/auth/login', { state: { successResetPassword: true } });

      } catch (e) {
        setStatus('The reset password link is invalid. Please try to reset your password again.');

        setSubmitting(false);

      } finally {
        setLoading(false);
      }
    }
  });

  if (!token) {
    return <Navigate to="/auth/login" />;
  }

  return (
    <form
      className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
      noValidate
      onSubmit={formik.handleSubmit}
    >
      <div className="text-center mb-10">
        <h1 className="text-dark mb-3">Reset New Password</h1>

        <div className="text-gray-400 fw-semibold fs-4">Enter your new password.</div>
      </div>

      {formik.status && (
        <div className="mb-lg-15 alert alert-danger">
          <div className="alert-text font-weight-bold">{formik.status}</div>
        </div>
      )}

      <div className="fv-row mb-7">
        <label className="form-label fw-bold text-dark fs-6">New password</label>
        <input
          placeholder="New password"
          type="password"
          autoComplete="off"
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>

      <div className="fv-row mb-10">
        <label className="form-label fw-bold text-dark fs-6">Confirm new password</label>
        <input
          type="password"
          placeholder="Password confirmation"
          autoComplete="off"
          {...formik.getFieldProps('confirmPassword')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.confirmPassword && formik.errors.confirmPassword,
            },
            {
              'is-valid': formik.touched.confirmPassword && !formik.errors.confirmPassword,
            }
          )}
        />
        {formik.touched.confirmPassword && formik.errors.confirmPassword && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.confirmPassword}</span>
            </div>
          </div>
        )}
      </div>

      <div className="d-flex flex-wrap justify-content-center pb-lg-0">
        <button
          type="submit"
          id="kt_password_reset_submit"
          className="btn btn-lg btn-primary fw-bold me-4"
        >
          <span className="indicator-label">Submit</span>
          {loading && (
            <span className="indicator-progress">
                Please wait...
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </button>
        <Link to="/auth/login">
          <button
            type="button"
            id="kt_login_password_reset_form_cancel_button"
            className="btn btn-lg btn-light-primary fw-bold"
            disabled={formik.isSubmitting || !formik.isValid}
          >
						Cancel
          </button>
        </Link>{' '}
      </div>
    </form>
  );
};