import { createApi } from '@reduxjs/toolkit/dist/query/react';

import { z3xBaseQuery } from 'app/utils/api.utils';
import { IFile } from 'app/types/file.interface';

interface IGetFilesArgs {
	file: string;
	page: number;
}

interface IGetFilesByModelArgs {
	model: string;
}

interface IGetFileByParentArgs {
	parent: string;
}

interface IGetShellDownloadLinkResponse {
	status: number;
	message: string;
	response: string[];
}

interface IGetShellDownloadLinkArgs {
	id: number;
}

export const fileApi = createApi({
  reducerPath: 'fileApi',
  baseQuery: z3xBaseQuery(),
  endpoints: (builder) => ({
    getFiles: builder.query<IFile[], IGetFilesArgs>({
      query: ({ file, page }) => ({
        url: '/files',
        params: { file, page }
      }),
    }),
    getFilesByModel: builder.query<IFile[], IGetFilesByModelArgs>({
      query: ({ model }) => ({
        url: '/files',
        params: { brand: model }
      })
    }),
    getFileByParent: builder.query<IFile[], IGetFileByParentArgs>({
      query: ({ parent }) => ({
        url: '/file/get-by-parent',
        params: { parent }
      }),
      transformResponse: (response: IFile[]) => {
        return response
          .sort((a, b) => a.file > b.file ? 1 : -1)
          .sort((a, b) => {
            if (a.isDir < b.isDir) return 1;
            if (a.isDir > b.isDir) return -1;
            return 0;
          });
      }
    }),
    getShellDownloadLink: builder.query<IGetShellDownloadLinkResponse, IGetShellDownloadLinkArgs>({
      query: ({ id }) => ({
        url: `/file/get-download-link/${id}`,
      })
    })
  })
});

export const {
  useLazyGetFilesQuery,
  useLazyGetFilesByModelQuery,
  useLazyGetFileByParentQuery,
  useLazyGetShellDownloadLinkQuery,
} = fileApi;