import { createApi } from '@reduxjs/toolkit/query/react';

import { z3xBaseQuery } from 'app/utils/api.utils';
import { HydraApiResponse } from 'app/types/hydraData';

interface IUserCrdBody {
  page?: number;
  itemsPerPage?: string;
}

interface IUserCrd {
  id: number;
  login: string;
  card: {
    card: string;
  }
  count: number;
}

interface ITransformedUserCrdResponse {
  logs: IUserCrd[],
  total: number
}

interface IUserCrdByIdResponse {
  id: number;
  login: string;
  count: number;
}

interface IUserCrdByIdBody {
  id: string;
}

export const userCrdApi = createApi({
  reducerPath: 'userCrd',
  baseQuery: z3xBaseQuery(),
  endpoints: (builder) => ({
    getAll: builder.query<ITransformedUserCrdResponse, IUserCrdBody>({
      query: ({ page, itemsPerPage }) => ({
        url: 'user-crds',
        params: { page, itemsPerPage, 'order[loginAt]': 'desc' }
      }),
      transformResponse: (response: HydraApiResponse<IUserCrd[]>) => {
        return {
          logs: response['hydra:member'],
          total: response['hydra:totalItems']
        };
      }
    }),
    getById: builder.query<IUserCrdByIdResponse, IUserCrdByIdBody>({
      query: (id) => ({
        url: 'user-crds/' + id,
      }),
    }),
  }),
});

export const {
  useGetAllQuery,
  useGetByIdQuery
} = userCrdApi;