import { FC, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import clsx from 'clsx';

import { themeModeSwitchHelper, useThemeMode } from '../partials/layout/theme-mode/ThemeModeProvider';
import { PageDataProvider, useLayout } from './core';
import { LaunchShellModal } from 'app/components/modals/launch-shell-modal/LaunchShellModal';
import { HeaderWrapper } from './components/header/HeaderWrapper';
import { MenuComponent } from '../assets/ts/components';
import { ScrollTop } from './components/ScrollTop';
import { Toolbar } from './components/toolbar/Toolbar';
import { Content } from './components/Content';
import { Footer } from './components/Footer';

export const MasterLayout: FC = () => {
  const location = useLocation();

  const { classes } = useLayout();
  const { mode } = useThemeMode();

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization();
    }, 500);
  }, [location.key]);

  useEffect(() => {
    themeModeSwitchHelper(mode);
  }, [mode]);

  return (
    <PageDataProvider>
      <div className="page d-flex flex-row flex-column-fluid">
        <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
          <HeaderWrapper />

          <div id="kt_content" className="content d-flex flex-column flex-column-fluid">
            <Toolbar />
            <div
              className={clsx(
                'd-flex flex-column-fluid align-items-start',
                classes.contentContainer.join(' ')
              )}
              id="kt_post"
            >
              <Content>
                <Outlet />
              </Content>
            </div>
          </div>

          <Footer />
        </div>
      </div>

      <ScrollTop />
      <LaunchShellModal />

    </PageDataProvider>
  );
};
