import { useState } from 'react';
import { useFormik } from 'formik';
import { emailConfirmationSchema } from 'app/modules/auth/resendEmailConfirmation/resendEmailConfirmation.schema';
import clsx from 'clsx';

import { useLazyResendEmailConfirmationQuery } from 'app/redux/api/auth/auth.api';
import { ConfirmRegistration } from 'app/components/shared/confirm-registration/ConfirmRegistration';

const initialValues = {
  email: ''
};

export const ResendEmailConfirmation = () => {
  const [sendResendEmailConfirmation] = useLazyResendEmailConfirmationQuery();

  const [loading, setLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);


  const formik = useFormik({
    initialValues,
    validationSchema: emailConfirmationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setLoading(true);
      try {
        await sendResendEmailConfirmation(values).unwrap();
        setIsSubmitted(true);
      } catch (error: any) {
        formik.setErrors({
          email: error?.data?.message ?? 'Something went wrong, please try again later.',
        });
        setSubmitting(false);
        setLoading(false);
      }
    }
  });

  if (isSubmitted) {
    return <ConfirmRegistration />;
  }

  return (
    <form
      className="form w-100"
      onSubmit={formik.handleSubmit}
      noValidate
      id="kt_login_signin_form"
    >
      <div className="text-center mb-10">
        <h1 className="text-dark mb-3">Enter Your Email to Resend Confirmation</h1>
      </div>

      <div className="fv-row">
        <label className="form-label fs-6 fw-bold text-dark">Email address</label>
        <input
          placeholder="Email"
          type="email"
          autoComplete="off"
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            { 'is-invalid': formik.touched.email && formik.errors.email },
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
        />
        {formik.touched.email && formik.errors.email && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.email}</span>
            </div>
          </div>
        )}
        <div className="text-center mt-10">
          <button
            type="submit"
            id="kt_sign_in_submit"
            className="btn btn-lg btn-primary w-100 mb-5"
            disabled={formik.isSubmitting || !formik.isValid}
          >
            {!loading && <span className="indicator-label">Continue</span>}
            {loading && (
              <span className="indicator-progress" style={{ display: 'block' }}>
                Please wait...
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </button>
        </div>
      </div>
    </form>
  );
};