import { createApi } from '@reduxjs/toolkit/query/react';

import { IProfileModel } from 'app/types/user.interface';
import { z3xBaseQuery } from 'app/utils/api.utils';


export const profileApi = createApi({
  reducerPath: 'profileApi',
  baseQuery: z3xBaseQuery(),
  endpoints: (builder) => ({
    updateProfile: builder.mutation<IProfileModel, IProfileModel>({
      query: (body) => ({
        headers: {
          'content-type': 'application/json'
        },
        url: '/profiles/edit',
        method: 'POST',
        body: JSON.stringify(body)
      })
    })
  })
});

export const { useUpdateProfileMutation } = profileApi;