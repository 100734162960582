import { FC, useEffect } from 'react';


import { useAppDispatch, useAppSelector } from 'app/hooks/redux';
import { useGetDownloadsListMutation } from 'app/redux/api/shell/shell.api';
import { setResults, setStatus } from 'app/redux/slices/shell/downloads.slice';
import { WithChildren } from 'app/types/withChildren';

const DOWNLOAD_LIST_INTERVAL_REQUEST = 3000;

export const PollingHelperDownloads: FC<WithChildren> = ({ children }) => {
  const dispatch = useAppDispatch();

  const isRun = useAppSelector(state => state.helperDownloads.isRun);

  const [requestDownloadsList] = useGetDownloadsListMutation();

  useEffect(() => {
    if (!isRun) {
      return;
    }

    fetchDownloadsList();
    const interval = setInterval(() => fetchDownloadsList(), DOWNLOAD_LIST_INTERVAL_REQUEST);

    return () => clearInterval(interval);
  }, [isRun]);

  const fetchDownloadsList = () => {
    dispatch(setStatus('loading'));
    requestDownloadsList({ softName: 'shell' }).unwrap()
      .then((data) => {
        if (!data.isError) {
          dispatch(setResults(data.data));
          dispatch(setStatus('success'));
          return;
        }
        throw new Error();
      })
      .catch(() => {
        dispatch(setStatus('error'));
        dispatch(setResults([]));
      });
  };

  return (
    <>
      {children}
    </>
  );
};
