import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';
import { useFormik } from 'formik';
import clsx from 'clsx';

import { GoogleLogin, FacebookLogin } from './socials';
import { useLoginMutation } from 'app/redux/api/auth/auth.api';
import { useLazyUserQuery } from 'app/redux/api/user/user.api';
import { loginSchema } from './login.schema';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setHash2fa } from 'app/redux/slices/user/user.slice';

const initialValues = {
  username: '',
  password: '',
};

type LocationState = {
	successVerify?: boolean
	successResetPassword?: boolean
} | null;

export const Login = () => {
  const location = useLocation().state as LocationState;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [sendLoginRequest] = useLoginMutation();
  const [sendUserRequest] = useLazyUserQuery();

  const [loading, setLoading] = useState(false);
  const [isResendEmailConfirmBtnVisible, setIsResendEmailConfirmBtnVisible] = useState(false);


  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      try {
        const loginResponse = await sendLoginRequest({ ...values }).unwrap();
        if (loginResponse?.['2fa']) {
          const { hash } = loginResponse;
          dispatch(setHash2fa(hash));
          navigate('/auth/2fa');
          return;
        }
        await sendUserRequest().unwrap();
      } catch (error: any) {
        //If the account is registered but requires email verification
        if (error?.data?.isVerified === false) {
          setIsResendEmailConfirmBtnVisible(true);
        } else {
          if (isResendEmailConfirmBtnVisible) setIsResendEmailConfirmBtnVisible(false);
        }
        setStatus(error?.data?.message ?? 'The login detail is incorrect');
        setSubmitting(false);
        setLoading(false);
      }
    }
  });

  return (
    <form
      className="form w-100"
      onSubmit={formik.handleSubmit}
      noValidate
      id="kt_login_signin_form"
    >
      <div className="text-center mb-10">
        <h1 className="text-dark mb-3">Sign In to Z3X User Portal</h1>
        <div className="text-gray-400 fw-semibold fs-4">
					New Here?{' '}
          <Link to="/auth/registration" className="link-primary fw-bold">
						Create an Account
          </Link>
        </div>
      </div>

      {formik.status && (
        <div className="mb-lg-10 alert alert-danger d-flex justify-content-between">
          <div className="alert-text font-weight-bold">{formik.status} </div>
          {isResendEmailConfirmBtnVisible && <Link to="/auth/resend-email-confirmation" className=" text-primary cursor-pointer">Resend email confirmation</Link>}
        </div>
      )}

      {!formik.status && location?.successVerify && (
        <div className="mb-10 bg-light-info p-8 rounded">
          <div className="text-info">
						Your account was successfully verified.
          </div>
        </div>
      )}

      {!formik.status && location?.successResetPassword && (
        <div className="mb-10 bg-light-info p-8 rounded">
          <div className="text-info">
						Your password was successfully reset.
          </div>
        </div>
      )}

      <div className="fv-row mb-10">
        <label className="form-label fs-6 fw-bold text-dark">Username or email address</label>
        <input
          placeholder="Username or email"
          {...formik.getFieldProps('username')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            { 'is-invalid': formik.touched.username && formik.dirty && formik.errors.username },
            {
              'is-valid': formik.touched.username && formik.dirty && !formik.errors.username,
            }
          )}
          type="text"
          name="username"
          autoComplete="off"
        />
        {formik.touched.username && formik.dirty && formik.errors.username && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.username}</span>
            </div>
          </div>
        )}
      </div>

      <div className="fv-row mb-10">
        <div className="d-flex justify-content-between mt-n5">
          <div className="d-flex flex-stack mb-2">

            <label className="form-label fw-bold text-dark fs-6 mb-0">
							Password
            </label>

            <Link
              to="/auth/forgot-password"
              className="link-primary fs-6 fw-bold"
              style={{ marginLeft: '5px' }}
            >
							Forgot Password ?
            </Link>

          </div>
        </div>
        <input
          placeholder="Password"
          type="password"
          autoComplete="off"
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.password && formik.dirty && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && formik.dirty && !formik.errors.password,
            }
          )}
        />
        {formik.touched.password && formik.dirty && formik.errors.password && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>

      <div className="text-center">
        <button
          type="submit"
          id="kt_sign_in_submit"
          className="btn btn-lg btn-primary w-100 mb-5"
          disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}
        >
          {!loading && <span className="indicator-label">Continue</span>}
          {loading && (
            <span className="indicator-progress" style={{ display: 'block' }}>
              Please wait...
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </button>

        {(process.env.REACT_APP_AUTH_GOOGLE_ID || process.env.REACT_APP_AUTH_FACEBOOK_ID) && (
          <div className="text-center text-muted text-uppercase fw-bold mb-5">
            or
          </div>
        )}

        <GoogleLogin />

        <FacebookLogin />

      </div>
    </form>
  );
};
