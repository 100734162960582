import { FC } from 'react';

import { useGetTotalUnreadNotificationsQuery } from 'app/redux/api/notification/notification.api';
import { NotificationMenuList } from 'metronic/layout/components/header/notification-menu/NotificationMenuList';
import { KTSVG } from 'metronic/helpers/components/KTSVG';

interface NotificationMenuProps {
	wrapperClsx: string
	svgClsx: string
}

export const NotificationMenu: FC<NotificationMenuProps> = ({ wrapperClsx, svgClsx }) => {
  const { data } = useGetTotalUnreadNotificationsQuery();

  return (
    <>
      <div
        className={wrapperClsx}
        data-kt-menu-trigger="click"
        data-kt-menu-attach="parent"
        data-kt-menu-placement="bottom-end"
        data-kt-menu-flip="bottom"
      >
        <KTSVG
          path="/media/icons/duotune/communication/com011.svg"
          className={svgClsx}
        />

        {!!(data && data > 0) && (
          <span className="bullet bullet-dot bg-warning h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink" />
        )}

      </div>
      <NotificationMenuList 
        totalUnreadNotifications={data}
      />
    </>
  );
};