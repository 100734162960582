import { lazy, FC, Suspense } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import TopBarProgress from 'react-topbar-progress-indicator';

import { getCSSVariableValue } from 'metronic/assets/ts/_utils';
import { ConfirmNewEmail } from '../modules/auth/signUpMethods/confirmNewEmail/confirmNewEmail';
import { MasterLayout } from 'metronic/layout/MasterLayout';
import { WithChildren } from '../types/withChildren';
import { ChangeEmail } from '../modules/auth/signUpMethods/changeEmail/changeEmail';

export const PrivateRoutes = () => {
  const NotificationPage = lazy(() => import('../modules/notifications/NotificationsPage'));
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'));

  const CardsPage = lazy(() => import('../modules/cards/CardsPageWrapper'));
  const FirmwaresPage = lazy(() => import('../modules/firmwares/FirmwaresPageWrapper'));
  const ToolsPage = lazy(() => import('../modules/tools/ToolsPageWrapper'));
  const FilesPage = lazy(() => import('../modules/files/FilesPageWrapper'));
  const DownloadsPage = lazy(() => import('../modules/downloads/DownloadsPageWrapper'));
  const SupportPage = lazy(() => import('../modules/support/SupportPageWrapper'));

  return (
    <Routes>

      <Route path="account/change-email" element={<ChangeEmail />} />
      <Route path="account/confirm-email" element={<ConfirmNewEmail />} />

      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path="auth/*" element={<Navigate to="/cards" />} />

        <Route
          path="account/*"
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />

        <Route
          path="notifications"
          element={
            <SuspensedView>
              <NotificationPage />
            </SuspensedView>
          }
        />

        <Route
          path="cards/*"
          element={
            <SuspensedView>
              <CardsPage />
            </SuspensedView>
          }
        />

        <Route
          path="firmwares"
          element={
            <SuspensedView>
              <FirmwaresPage />
            </SuspensedView>
          }
        />

        <Route
          path="tools"
          element={
            <SuspensedView>
              <ToolsPage />
            </SuspensedView>
          }
        />

        <Route
          path="files"
          element={
            <SuspensedView>
              <FilesPage />
            </SuspensedView>
          }
        />

        <Route
          path="downloads"
          element={
            <SuspensedView>
              <DownloadsPage />
            </SuspensedView>
          }
        />

        <Route
          path="support/:tab"
          element={
            <SuspensedView>
              <SupportPage />
            </SuspensedView>
          }
        />

        {/* Page Not Found */}
        <Route path="*" element={<Navigate to="/error/404" />} />
      </Route>
    </Routes>
  );
};

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--kt-primary');
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};
