import { FC, useState } from 'react';

import { useLazyResendEmailConfirmationQuery } from 'app/redux/api/auth/auth.api';
import { toAbsoluteUrl } from 'metronic/helpers/AssetHelpers';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';
import { toast } from 'react-toastify';

interface IConfirmRegistrationProps {
    email?: string;
}

export const ConfirmRegistration: FC<IConfirmRegistrationProps> = ( { email } ) => {
  const [showResentMessage, setShowResentMessage] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const [sendResendEmailConfirmation] = useLazyResendEmailConfirmationQuery();

  const handleRedirectToResendEmailConfirmation = async() => {
    //if there is email available, immediately resend email verification
    if (email) {
      try {
        await sendResendEmailConfirmation( { email } ).unwrap();
        setShowResentMessage(true);
        setTimeout(() => setShowResentMessage(false), 3000);
      } catch (error: any) {
        toast.error('Oops! We encountered an issue and couldn’t send the confirmation email. Please try again later or contact support if the problem persists.');
      }
    //redirect to page where user will be asked for email to send email verification
    }  else {
      const goalUrl = '/auth/resend-email-confirmation';
      if (location.pathname === goalUrl) navigate(0);
      else navigate(goalUrl);
    }
  };

  return (
    <div className="d-flex flex-column flex-column-fluid text-center p-10 py-lg-15">
      <div className="pt-lg-10 mb-10">
        <h1 className="fw-bold fs-2qx text-gray-800 mb-10">Thanks for registering!</h1>
        <div className="fw-semibold fs-3 text-muted mb-10">We sent you an email to confirm your registration</div>
      </div>
      <div
        className="d-flex flex-row-auto bgi-no-repeat bgi-position-x-center bgi-size-contain bgi-position-y-bottom min-h-100px min-h-lg-250px"
        style={{ backgroundImage: `url('${toAbsoluteUrl('/media/illustrations/sketchy-1/17.png')}')` }}
      />
      <div className="position-relative mb-lg-10 pt-10">
        {showResentMessage && <div className="position-absolute mt-10 bg-light-success p-4 rounded" style={ {
          top: '-44px', right: '-32px'
        } }>
          <div className="text-success">
              We have resent your confirmation
          </div>
        </div>}
        <div className="fw-semibold fs-3 text-muted">Didn't receive an email? <span className="fw-bold fs-3 text-primary cursor-pointer" onClick={handleRedirectToResendEmailConfirmation}>Resend</span></div>
      </div>
    </div>
  );
};