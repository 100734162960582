import { useAppSelector } from 'app/hooks/redux';
import { MenuItem } from './MenuItem';

export const MenuInner = () => {
  const currentUser = useAppSelector(state => state.user.currentUser);

  if (currentUser) {
    return (
      <>
        <MenuItem title="Cards" to="/cards" />
        <MenuItem title="Firmwares" to="/firmwares" />
        <MenuItem title="Tools" to="/tools" />
        <MenuItem title="Files" to="/files" />
        <MenuItem title="Downloads" to="/downloads" />
        {/* <MenuItem title="Support" to="/support/ticket" />*/}
      </>
    );
  }

  return (
    <>
      <MenuItem title="Home" to="/" />
    </>
  );
};
