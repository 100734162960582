import { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useFormik } from 'formik';
import clsx from 'clsx';

import { useSocialAuthMutation } from 'app/redux/api/user/user-socials.api';
import { socialVerifyingSchema } from './social-verifying.schema';
import { ConfirmRegistration } from 'app/components/shared/confirm-registration/ConfirmRegistration';
import { SocialAuthDataType } from 'app/types/auth.interface';
import { VERIFY_EMAIL_URL } from 'app/utils/auth.utils';


export const SocialVerifying: FC = () => {
  const navigate = useNavigate();
  const socialAuthDataLocation = useLocation().state as SocialAuthDataType;

  const [requestSocialAuth] = useSocialAuthMutation();

  const [isSubmit, setIsSubmit] = useState(false);
  const [loading, setLoading] = useState(false);

  const formik = useFormik<{ email: string }>({
    initialValues: { email: '' },
    validationSchema: socialVerifyingSchema,
    onSubmit: async (values, { setStatus }) => {
      setLoading(true);
      try {
        await requestSocialAuth({
          ...socialAuthDataLocation,
          email: values.email,
          urlTo: VERIFY_EMAIL_URL()
        }).unwrap();

        setIsSubmit(true);
      } catch (e: any) {
        if (e.status === 406) {
          setStatus(e.data.error);
          return;
        }
        setStatus('Unknown error');
      } finally {
        setLoading(false);
      }
    }
  });

  useEffect(() => {
    if (!socialAuthDataLocation?.type) {
      navigate('/auth/login');
    }
  }, []);

  const clearErrors = () => {
    formik.setTouched({}, false);
    formik.setStatus(null);
  };

  if (isSubmit) {
    return <ConfirmRegistration email={formik.values.email} />;
  }

  return (
    <form
      className="form w-100"
      onSubmit={formik.handleSubmit}
      onChange={clearErrors}
      noValidate
    >
      <div className="text-center mb-10">
        <h1 className="text-dark text-center mb-5">Verifying</h1>
        <div className="text-gray-600 fw-semibold fs-4">
					We could not get your email address via
          <span className="text-capitalize"> {socialAuthDataLocation.type}</span>.<br />
					Please, enter it.
        </div>
      </div>

      <div className="fv-row mb-10">
        <label className="form-label fs-6 fw-bold text-dark">Email</label>
        <input
          type="text"
          autoComplete="off"
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            { 'is-invalid': formik.touched.email && formik.errors.email },
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
        />
        {formik.touched.email && formik.errors.email && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.email}</span>
            </div>
          </div>
        )}
        {formik.status && (
          <div className="mt-4 alert alert-danger">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        )}
      </div>

      <div className="text-center">
        <button
          type="submit"
          id="kt_sign_in_submit"
          className="btn btn-lg btn-primary w-100"
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className="indicator-label">Submit</span>}
          {loading && (
            <span className="indicator-progress" style={{ display: 'block' }}>
              Please wait...
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </button>
      </div>
    </form>
  );
};