import { FC } from 'react';
import { useNavigate } from 'react-router';

import { toAbsoluteUrl } from 'metronic/helpers';

export const Logo: FC = () => {
  const navigate = useNavigate();

  return (
    <a
      className="mb-12 cursor-pointer"
      data-testid="link"
      onClick={() => navigate('/cards')} 
    >
      <img
        alt="Logo"
        src={toAbsoluteUrl('/media/logos/z3x-logo.svg')}
        className="theme-dark-show h-45px"
      />
      <img
        alt="Logo"
        src={toAbsoluteUrl('/media/logos/z3x-logo-dark.svg')}
        className="theme-light-show h-45px"
      />
    </a>
  );
};