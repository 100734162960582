import { createApi } from '@reduxjs/toolkit/dist/query/react';

import { z3xBaseQuery } from 'app/utils/api.utils';
import { IForumPost } from 'app/types/support.interface';


interface ISearchFormPostsArgs {
  tags: string;
  post: string;
}

type ISearchFormPostsResponse = { posts: IForumPost[] } | { error: string };

export const forumApi = createApi({
  reducerPath: 'forumApi',
  baseQuery: z3xBaseQuery(),
  endpoints: (builder) => ({
    searchForumPosts: builder.mutation<ISearchFormPostsResponse, ISearchFormPostsArgs>({
      query: (body) => ({
        method: 'POST',
        url: '/forum/post-search',
        body
      }),
    })
  }),
});

export const {
  useSearchForumPostsMutation
} = forumApi;