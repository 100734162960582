import { FC } from 'react';
import clsx from 'clsx';

import { ShellMenuControl } from './ShellMenuControl';
import { useAppSelector } from 'app/hooks/redux';
import { KTSVG } from 'metronic/helpers';

interface IShellMenuProps {
	wrapperClsx: string;
	svgClsx: string;
}

export const ShellMenu: FC<IShellMenuProps> = ({ wrapperClsx, svgClsx }) => {
  const { status } = useAppSelector(state => state.shell);

  const bulletClsx = clsx(
    'bullet bullet-dot bullet-shell-icon h-3px w-3px position-absolute translate-middle',
    { 'bg-success': status === 'connected' },
    { 'bg-warning': status === 'pending' },
    { 'bg-danger': status ==='no connection' },
    { 'animation-blink': status !== 'connected' }
  );

  return (
    <>
      <div
        className={wrapperClsx}
        data-kt-menu-trigger="click"
        data-kt-menu-attach="parent"
        data-kt-menu-placement="bottom-end"
        data-kt-menu-flip="bottom"
      >
        <KTSVG
          path="/media/icons/duotune/general/gen002.svg"
          className={svgClsx}
        />

        <span className={bulletClsx} />
      </div>

      <ShellMenuControl status={status} />
    </>
  );
};