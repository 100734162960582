import { FC } from 'react';

import { setShowLaunchShellModal } from 'app/redux/slices/shell/shell.slice';
import { ShellMenuFolder } from './components/ShellMenuFolder';
import { useAppDispatch } from 'app/hooks/redux';
import { toAbsoluteUrl } from 'metronic/helpers';
import { IShellStatus } from 'app/types/store.interface';

interface IShellMenuControlProps {
	status: IShellStatus;
}

export const ShellMenuControl: FC<IShellMenuControlProps> = ({ status }) => {
  const dispatch = useAppDispatch();

  return (
    <div
      className="menu menu-sub menu-sub-dropdown menu-column w-250px w-lg-325px"
      data-kt-menu="true"
    >
      <div
        className={`d-flex flex-column flex-center bgi-no-repeat rounded px-9 py-10 gap-2 ${status === 'connected' ? 'rounded-bottom-0' : ''}`}
        style={{ backgroundImage: `url('${toAbsoluteUrl('/media/misc/menu-header-bg.jpg')}')` }}
      >
        <h3 className="text-white fw-semibold mb-3">Z3X Helper Status</h3>

        {status === 'connected' && (
          <div className="d-flex align-items-center gap-2">
            <span className="bullet bullet-dot bg-success h-15px w-15px" />
            <span className="text-white lh-1" style={{ marginTop: 1 }}> Connected</span>
          </div>
        )}
        {status === 'pending' && (
          <span className="badge bg-warning py-2 px-3">Pending</span>
        )}
        {status === 'no connection' && (
          <>
            <div className="d-flex align-items-center gap-2 mb-4">
              <span className={'bullet bullet-dot bg-danger h-15px w-15px'} />
              <span className="text-white lh-1" style={{ marginTop: 1 }}>No connection</span>
            </div>

            <span
              className="badge bg-success py-2 px-3 cursor-pointer w-125px justify-content-center"
              onClick={() => dispatch(setShowLaunchShellModal(true))}
            >
							Launch Z3X Helper
            </span>
          </>
        )}
      </div>

      {status === 'connected' && (
        <ShellMenuFolder />
      )}
    </div>
  );
};
