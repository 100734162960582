import * as qs from 'qs';
import { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';

import { useConfirmNewEmailMutation } from 'app/redux/api/user/sign-up-methods.api';
import { LayoutSplashScreen } from 'metronic/layout/core';
import { CustomMessage } from 'app/components/shared/CustomMessage';


export const ChangeEmail: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [showSplashScreen, setShowSplashScreen] = useState(true);

  const [requestConfirmNewEmail] = useConfirmNewEmailMutation();

  useEffect(() => {
    const params = qs.parse(location.search.slice(1));

    if (!params.token) {
      navigate('/error/500');
    }

    (async () => {
      try {
        const token = String(params.token);
        await requestConfirmNewEmail(token).unwrap();
      } catch (e) {
        navigate('/error/500');
      } finally {
        setShowSplashScreen(false);
      }
    })();

  }, []);

  if (showSplashScreen) return <LayoutSplashScreen />;

  return (
    <CustomMessage
      header="Confirmation"
      text="We have sent a link to your new mailing address to change your current address"
      homeButton={false}
      footer={false}
    />
  );
};