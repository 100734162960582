import { configureStore } from '@reduxjs/toolkit';
import { PreloadedState } from 'redux';

import { rootMiddleware, rootReducer } from './setup';

export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => rootMiddleware(getDefaultMiddleware),
    preloadedState
  });
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
