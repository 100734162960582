import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import { useSocialAuthMutation } from 'app/redux/api/user/user-socials.api';
import { SocialAuthDataType } from 'app/types/auth.interface';
import { useLazyUserQuery } from 'app/redux/api/user/user.api';
import { FacebookProvider } from 'app/providers/index';
import { toAbsoluteUrl } from 'metronic/helpers/AssetHelpers';

export const FacebookLogin: FC = () => (
  <FacebookProvider>
    <FacebookButton />
  </FacebookProvider>
);

const FacebookButton: FC = () => {
  const navigate = useNavigate();

  const [requestSocialAuth] = useSocialAuthMutation();
  const [sendUserRequest] = useLazyUserQuery();

  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!error) {
      return;
    }
    const timeout = setTimeout(() => setError(''), 10000);
    return () => clearTimeout(timeout);
  }, [error]);

  const handleSocialAuthByFacebook = async (authResponse: facebook.AuthResponse) => {
    const state: SocialAuthDataType = { type: 'facebook', ...authResponse };
    try {
      await requestSocialAuth(state).unwrap();
      await sendUserRequest().unwrap();
    } catch (e: any) {
      if (e.status === 412) {
        navigate('/auth/login/verifying', { state });
        return;
      }
      if (e.status === 406) {
        setError(e.data.error);
        return;
      }
      setError('Unknown error with Google.');
    } finally {
      setLoading(false);
    }
  };

  const facebookLogin = () => {
    setLoading(true);
    window.FB.login((response) => {
      if (response.authResponse) {
        handleSocialAuthByFacebook(response.authResponse);
      } else {
        setLoading(false);
      }
    });
  };

  return (
    <div className="fv-row mb-5">
      <a
        onClick={facebookLogin}
        className="btn btn-flex flex-center btn-light btn-lg w-100"
      >
        <img
          alt="Logo"
          src={toAbsoluteUrl('/media/svg/brand-logos/facebook-4.svg')}
          className="h-20px me-3"
        />
        {!loading && 'Continue with Facebook'}
        {loading && (
          <span className="indicator-progress" style={{ display: 'block' }}>
						Please wait...
            <span className="spinner-border spinner-border-sm align-middle ms-2" />
          </span>
        )}
      </a>

      {error && (
        <div className="fv-plugins-message-container">
          <div className="fv-help-block">
            <span role="alert">Some error with Facebook login.</span>
          </div>
        </div>
      )}
    </div>
  );
};