import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import clsx from 'clsx';

import { forgotPasswordSchema } from './forgotPassword.schema';
import { useResetPasswordMutation } from 'app/redux/api/auth/auth.api';


const initialValues = {
  email: '',
};

export const ForgotPassword = () => {
  const [requestResetPassword] = useResetPasswordMutation();

  const [loading, setLoading] = useState(false);
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined);

  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      setHasErrors(undefined);

      try {
        await requestResetPassword(values).unwrap();
        setHasErrors(false);
        setLoading(false);
      } catch (error: any) {
        setHasErrors(true);
        setLoading(false);
        setSubmitting(false);
        const errorMessage = error?.data?.error || error?.data?.message;
        if (errorMessage) {
          setStatus(errorMessage);
        } else {
          setStatus('The login detail is incorrect');
        }
      }
    },
  });

  return (
    <>
      <form
        className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
        noValidate
        id="kt_login_password_reset_form"
        onSubmit={formik.handleSubmit}
      >
        <div className="text-center mb-10">
          <h1 className="text-dark mb-3">Forgot Password ?</h1>
          <div className="text-gray-400 fw-semibold fs-4">Enter your email to reset your password.</div>
        </div>

        {hasErrors === true && (
          <div className="mb-lg-15 alert alert-danger">
            <div className="alert-text font-weight-bold">
              {formik.status}
            </div>
          </div>
        )}

        {hasErrors === false && (
          <div className="mb-10 bg-light-info p-8 rounded">
            <div className="text-info">Sent password reset. Please check your email</div>
          </div>
        )}

        <div className="fv-row mb-10">
          <label className="form-label fw-bold text-gray-900 fs-6">Email</label>
          <input
            type="email"
            placeholder=""
            autoComplete="off"
            {...formik.getFieldProps('email')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              { 'is-invalid': formik.touched.email && formik.errors.email },
              {
                'is-valid': formik.touched.email && !formik.errors.email,
              }
            )}
          />
          {formik.touched.email && formik.errors.email && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                <span role="alert">{formik.errors.email}</span>
              </div>
            </div>
          )}
        </div>

        <div className="d-flex flex-wrap justify-content-center pb-lg-0">
          <button
            type="submit"
            id="kt_password_reset_submit"
            className="btn btn-lg btn-primary fw-bold me-4"
          >
            <span className="indicator-label">Submit</span>
            {loading && (
              <span className="indicator-progress">
                Please wait...
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </button>
          <Link to="/auth/login">
            <button
              type="button"
              id="kt_login_password_reset_form_cancel_button"
              className="btn btn-lg btn-light-primary fw-bold"
              disabled={formik.isSubmitting || !formik.isValid}
            >
							Cancel
            </button>
          </Link>{' '}
        </div>
      </form>
    </>
  );
};
