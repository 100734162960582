import { Navigate, Routes } from 'react-router-dom';


export const Logout = () => {
  return (
    <Routes>
      <Navigate to="/auth/login" />
    </Routes>
  );
};
