import { createApi } from '@reduxjs/toolkit/query/react';

import { HydraApiResponse } from 'app/types/hydraData';
import { ICardDownload } from 'app/types/card.interface';
import { z3xBaseQuery } from 'app/utils/api.utils';

interface IGetDownloadTempsCardByIdArgs {
	id: number;
	page: number;
	itemsPerPage: number;
}

interface IGetDownloadTempsCardById {
	total: number;
	downloads: ICardDownload[];
}

export const cardDownloadsApi = createApi({
  reducerPath: 'cardDownloadsApi',
  baseQuery: z3xBaseQuery({ type: 'ld+json' }),
  endpoints: (builder) => ({
    getDownloadsCardById: builder.query<IGetDownloadTempsCardById, IGetDownloadTempsCardByIdArgs>({
      query: ({ id, page, itemsPerPage }) => ({
        url: `/cards/${id}/downloads`,
        params: { page, itemsPerPage, 'order[date]': 'desc' }
      }),
      transformResponse: (response: HydraApiResponse<ICardDownload[]>) => {
        return {
          downloads: response['hydra:member'],
          total: response['hydra:totalItems']
        };
      }
    }),
  })
});

export const {
  useLazyGetDownloadsCardByIdQuery
} = cardDownloadsApi;