import { IShellDownload } from '../types/shell.interface';

export const RESELLER_LINK = 'https://z3x-team.com/where-to-buy/';
export const SHELL_DOWNLOAD_LINK = 'https://z3x-team.com/soft/z3x_helper.exe';
export const SHELL_PROTOCOL = 'z3xhelper://';

export const getUrlTorrentFile = (path: string) => `http://downloads.z3x-team.com/torrents/${encodeURIComponent(path)}.torrent`;

export const getFileFromPath = (path: string): string => {
  const splitPath = path.split('/');
  return splitPath[splitPath.length - 1];
};

export const checkIsFileInDownloadsList = (filePath: string, downloads?: IShellDownload[] | undefined): boolean => {
  if (!downloads) {
    return false;
  }
  const file = getFileFromPath(filePath);

  return Boolean(downloads.find(item => item.name === file));
};


export const SHELL_ERRORS = {
  generalDownloadFile: 'File download error',
  addDownloadFile: 'Error to add file download',
  generalBrowsFile: 'File browse error',
  generalGetDownloadList: 'Error getting download list',
  generalRunTool: 'Error running tool',
  runSoftware: 'Error launching the software',
  generalInstallTool: 'Error installing tool',
  installSoftware: 'Software installation error',
  generalDownloadTool: 'Error downloading tool',
  toolStatus: 'Error setting status',
};
