import { Route, Routes } from 'react-router-dom';

import { MasterLayout } from 'metronic/layout/MasterLayout';
import { HomePage } from 'app/pages/home/HomePage';


export const HomeRoutes = () => (
  <Routes>
    <Route element={<MasterLayout />}>
      <Route index element={<HomePage />} />
    </Route>
  </Routes>
);
