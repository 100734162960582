import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import clsx from 'clsx';

import { PasswordMeterComponent } from 'metronic/assets/ts/components';
import { ConfirmRegistration } from 'app/components/shared/confirm-registration/ConfirmRegistration';
import { useRegisterMutation } from 'app/redux/api/auth/auth.api';
import { registrationSchema } from './registration.schema';
// import { toAbsoluteUrl } from 'metronic/helpers';

const initialValues = {
  username: '',
  email: '',
  plainPassword: '',
  confirmPassword: '',
  acceptTerms: false,
};

export const Registration = () => {
  const [sendRegisterRequest] = useRegisterMutation();

  const [isSubmit, setIsSubmit] = useState(false);
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      sendRegisterRequest({ ...values })
        .unwrap()
        .then(() => setIsSubmit(true))
        .catch((error) => {
          const errorMessage = error?.data?.message;
          if (errorMessage) {
            setStatus(errorMessage);
          } else {
            setStatus('The registration details is incorrect');
          }
          setSubmitting(false);
        })
        .finally(() => setLoading(false));
    },
  });

  useEffect(() => {
    PasswordMeterComponent.bootstrap();
  }, []);

  if (isSubmit) {
    return <ConfirmRegistration email={formik.values.email}/>;
  }

  return (
    <form
      className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
      noValidate
      id="kt_login_signup_form"
      onSubmit={formik.handleSubmit}
    >
      <div className="mb-10 text-center">
        <h1 className="text-dark mb-3">Create an Account</h1>

        <div className="text-gray-400 fw-semibold fs-5">
					Already have an account?
          <Link to="/auth/login" className="link-primary fw-bold" style={{ marginLeft: '5px' }}>
						Sign In!
          </Link>
        </div>
      </div>

      {/*<button type='button' className='btn btn-light-primary fw-bold w-100 mb-10'>*/}
      {/*  <img*/}
      {/*    alt='Logo'*/}
      {/*    src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}*/}
      {/*    className='h-20px me-3'*/}
      {/*  />*/}
      {/*  Sign in with Google*/}
      {/*</button>*/}

      {/*<div className='d-flex align-items-center mb-10'>*/}
      {/*  <div className='border-bottom border-gray-300 mw-50 w-100'></div>*/}
      {/*  <span className='fw-semibold text-gray-400 fs-7 mx-2'>OR</span>*/}
      {/*  <div className='border-bottom border-gray-300 mw-50 w-100'></div>*/}
      {/*</div>*/}

      {formik.status && (
        <div className="mb-lg-15 alert alert-danger">
          <div className="alert-text font-weight-bold">{formik.status}</div>
        </div>
      )}

      <div className="fv-row mb-7">
        <label className="form-label fw-bold text-dark fs-6">Username</label>
        <input
          placeholder="Username"
          type="text"
          autoComplete="off"
          {...formik.getFieldProps('username')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.username && formik.errors.username,
            },
            {
              'is-valid': formik.touched.username && !formik.errors.username,
            }
          )}
        />
        {formik.touched.username && formik.errors.username && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.username}</span>
            </div>
          </div>
        )}
      </div>

      <div className="fv-row mb-7">
        <label className="form-label fw-bold text-dark fs-6">Email</label>
        <input
          placeholder="Email"
          type="email"
          autoComplete="off"
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            { 'is-invalid': formik.touched.email && formik.errors.email },
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
        />
        {formik.touched.email && formik.errors.email && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>

      <div className="mb-10 fv-row" data-kt-password-meter="true">
        <div className="mb-1">
          <label className="form-label fw-bold text-dark fs-6">Password</label>
          <div className="position-relative mb-3">
            <input
              type="password"
              placeholder="Password"
              autoComplete="off"
              {...formik.getFieldProps('plainPassword')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid': formik.touched.plainPassword && formik.errors.plainPassword,
                },
                {
                  'is-valid': formik.touched.plainPassword && !formik.errors.plainPassword,
                }
              )}
            />
            {formik.touched.plainPassword && formik.errors.plainPassword && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">{formik.errors.plainPassword}</span>
                </div>
              </div>
            )}
          </div>
          <div
            className="d-flex align-items-center mb-3"
            data-kt-password-meter-control="highlight"
          >
            <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
            <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
            <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
            <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px"></div>
          </div>
        </div>
        <div className="text-muted">
					Use 6 or more characters with a mix of letters, numbers & symbols.
        </div>
      </div>

      <div className="fv-row mb-5">
        <label className="form-label fw-bold text-dark fs-6">Confirm Password</label>
        <input
          type="password"
          placeholder="Password confirmation"
          autoComplete="off"
          {...formik.getFieldProps('confirmPassword')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.confirmPassword && formik.errors.confirmPassword,
            },
            {
              'is-valid': formik.touched.confirmPassword && !formik.errors.confirmPassword,
            }
          )}
        />
        {formik.touched.confirmPassword && formik.errors.confirmPassword && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.confirmPassword}</span>
            </div>
          </div>
        )}
      </div>

      <div className="fv-row mb-10">
        <div className="form-check form-check-custom form-check-solid">
          <input
            className="form-check-input"
            type="checkbox"
            id="kt_login_toc_agree"
            {...formik.getFieldProps('acceptTerms')}
          />
          <label
            className="form-check-label fw-semibold text-gray-700 fs-6"
            htmlFor="kt_login_toc_agree"
          >
						I Agree the{' '}
            <Link to="/policy" className="ms-1 link-primary">
							terms and conditions
            </Link>
						.
          </label>
          {formik.touched.acceptTerms && formik.errors.acceptTerms && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                <span role="alert">{formik.errors.acceptTerms}</span>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="text-center">
        <button
          type="submit"
          id="kt_sign_up_submit"
          className="btn btn-lg btn-primary w-100 mb-5"
          disabled={formik.isSubmitting || !formik.isValid || !formik.values.acceptTerms}
        >
          {!loading && <span className="indicator-label">Submit</span>}
          {loading && (
            <span className="indicator-progress" style={{ display: 'block' }}>
              Please wait...{' '}
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </button>
        <Link to="/auth/login">
          <button
            type="button"
            id="kt_login_signup_form_cancel_button"
            className="btn btn-lg btn-light-primary w-100 mb-5"
          >
						Cancel
          </button>
        </Link>
      </div>
    </form>
  );
};
