import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IShellStatus } from 'app/types/store.interface';

export interface IShellState {
	status: IShellStatus;
  showLaunchShellModal: boolean
}

const initialState: IShellState = {
  status: 'pending',
  showLaunchShellModal: false
};

export const shellSlice = createSlice({
  name: 'shell',
  initialState,
  reducers: {
    setShellStatus: (state, action: PayloadAction<IShellStatus>) => {
      state.status = action.payload;
    },
    setShowLaunchShellModal: (state, action: PayloadAction<boolean>) => {
      state.showLaunchShellModal = action.payload;
    }
  },
});

export const {
  setShellStatus,
  setShowLaunchShellModal,
} = shellSlice.actions;

export default shellSlice.reducer;