import { FC, ReactNode } from 'react';
import { Link } from 'react-router-dom';

import { toAbsoluteUrl } from '../../../metronic/helpers';

interface ICustomMessageDefault {
	logo?: boolean;
	homeButton?: boolean;
	footer?: boolean;
}

interface ICustomMessageChildren {
	children: ReactNode;
	header?: never;
	text?: never;
}

interface ICustomMessageText {
	header: string;
	text: string;
	children?: never;
}

type CustomMessageProps = ICustomMessageDefault & (ICustomMessageChildren | ICustomMessageText);

export const CustomMessage: FC<CustomMessageProps> = ({
  children,
  header,
  text,
  logo = true,
  homeButton = true,
  footer = true
}) => (
  <div className="d-flex flex-column flex-root">
    <div className="d-flex flex-column flex-column-fluid">
      <div className="d-flex flex-column flex-column-fluid text-center p-10 py-lg-15">
        {logo && (
          <Link to="/" className="mb-10 pt-lg-10">
            <img
              alt="Logo"
              src={toAbsoluteUrl('/media/logos/z3x-logo.svg')}
              className="h-40px mb-5 theme-dark-show"
            />
            <img
              alt="Logo"
              src={toAbsoluteUrl('/media/logos/z3x-logo-dark.svg')}
              className="h-40px mb-5 theme-light-show"
            />
          </Link>
        )}
        <div className={logo ? 'pt-lg-10 mb-10' : ''}>
          {children}

          {!children && (
            <>
              <h1 className="fw-bold fs-2qx text-gray-800 mb-10">{header}</h1>
              <div className="fw-semibold fs-3 text-muted mb-15">{text}</div>
            </>
          )}

          {homeButton && (
            <div className="text-center mb-10">
              <Link to="/" className="btn btn-lg btn-primary fw-bold">
								Go to homepage
              </Link>
            </div>
          )}
        </div>
        <div
          className="d-flex flex-row-auto bgi-no-repeat bgi-position-x-center bgi-size-contain bgi-position-y-bottom min-h-100px min-h-sm-250px min-h-lg-350px"
          style={{
            backgroundImage: `url('${toAbsoluteUrl('/media/illustrations/sketchy-1/17.png')}')`,
          }}
        ></div>
      </div>
      {footer && (
        <div className="d-flex flex-center flex-column-auto p-10" />
      )}
    </div>
  </div>
);