interface KTCardSpinnerProps {
    style?: React.CSSProperties;
}

export const KTCardSpinner = (props: KTCardSpinnerProps) => {
  const { style, ...rest } = props;

  return (
    <div className="d-flex justify-content-center align-items-center h-100px" style={style} {...rest}>
      <span className="spinner-border h-20px w-20px spinner-border-sm align-middle" />
    </div>
  );
};