import { createApi } from '@reduxjs/toolkit/query/react';

import { z3xBaseQuery } from 'app/utils/api.utils';
import { CHANGE_EMAIL_URL, CONFIRM_NEW_EMAIL_URL } from 'app/utils/auth.utils';

interface IChangeEmailResponse {
	success: string;
}

interface IChangeEmailArgs {
	newEmail: string;
	confirmPassword: string;
}

interface IChangePasswordResponse {
	message: string;
}

interface IChangePasswordArgs {
	password: string,
	newPassword: string
}

interface IChange2FArgs {
  id: number;
  userPortal2fa: boolean;
}

export const signUpMethodsApi = createApi({
  reducerPath: 'signUpMethodsApi',
  baseQuery: z3xBaseQuery(),
  endpoints: (builder) => ({
    changeEmail: builder.mutation<IChangeEmailResponse, IChangeEmailArgs>({
      query: ({ newEmail, confirmPassword }) => ({
        url: '/change-email',
        method: 'POST',
        body: {
          newEmail: newEmail,
          password: confirmPassword,
          urlTo: CHANGE_EMAIL_URL()
        }
      })
    }),
    confirmNewEmail: builder.mutation<null, string>({
      query: (token) => ({
        url: '/change-email/confirm',
        method: 'POST',
        body: {
          token,
          urlTo: CONFIRM_NEW_EMAIL_URL()
        }
      })
    }),
    changeNewEmail: builder.mutation<null, string>({
      query: (token) => ({
        url: '/change-email/change',
        method: 'POST',
        body: { token }
      })
    }),
    changePassword: builder.mutation<IChangePasswordResponse, IChangePasswordArgs>({
      query: ({ password, newPassword }) => ({
        url: '/users/change-password',
        method: 'POST',
        body: { password, newPassword }
      })
    }),
    change2FA: builder.mutation<null, IChange2FArgs>({
      query: ( { id, userPortal2fa } ) => ({
        url: '/users/edit/' + id,
        method: 'PATCH',
        headers: { 'Content-Type': 'application/merge-patch+json' },
        body: JSON.stringify({ userPortal2fa })
      })
    }),
  })
});

export const {
  useChangeEmailMutation,
  useConfirmNewEmailMutation,
  useChangeNewEmailMutation,
  useChangePasswordMutation,
  useChange2FAMutation
} = signUpMethodsApi;
