import { FC } from 'react';

import { WithChildren } from '../types/withChildren';
import {
  PollingShellStatus,
  PollingHelperDownloads,
  FirstShellLaunchConfirmation
} from '../hocs/shell';

export const ShellProvider: FC<WithChildren> = ({ children }) => {
  return (
    <PollingShellStatus>
      <FirstShellLaunchConfirmation>
        <PollingHelperDownloads>
          {children}
        </PollingHelperDownloads>
      </FirstShellLaunchConfirmation>
    </PollingShellStatus>
  );
};
