import { createContext, useContext } from 'react';

export interface NotificationsToolbarContextProps {
	selected: number[]
	isAllSelected: boolean
	
	onSelect: (id: number) => void
	onSelectAll: () => void
	refetchAll: () => void
	onReadItem: (id: number) => void
	onReadAll: (indexes: number[]) => void
}

const NotificationsToolbarContextInitial: NotificationsToolbarContextProps = {
  selected: [],
  isAllSelected: false,

  onReadItem: () => {},
  onReadAll: () => {},
  onSelect: () => {},
  onSelectAll: () => {},
  refetchAll: () => {},
};

export const NotificationsToolbarContext = createContext<NotificationsToolbarContextProps>(NotificationsToolbarContextInitial);
export const useGetNotificationsToolbarContext = () => useContext(NotificationsToolbarContext);