import { FC } from 'react';
import { Helmet } from 'react-helmet';

import { WithChildren } from 'app/types/withChildren';

export const FacebookProvider: FC<WithChildren> = ({ children }) => {

  if (!process.env.REACT_APP_AUTH_FACEBOOK_ID) {
    return null;
  }

  return (
    <>

      <Helmet>
        <script>
          {`
						window.fbAsyncInit = function() {
							FB.init({
								appId: '${process.env.REACT_APP_AUTH_FACEBOOK_ID}',
								autoLogAppEvents: true,
								xfbml: true,
								version: 'v15.0'
							});
						};
					`}
        </script>
        <script>
          {`
						(function(d, s, id){
						var js, fjs = d.getElementsByTagName(s)[0];
						if (d.getElementById(id)) {return;}
						js = d.createElement(s); js.id = id;
						js.src = "https://connect.facebook.net/en_US/sdk.js";
						fjs.parentNode.insertBefore(js, fjs);
						}(document, 'script', 'facebook-jssdk'));
					`}
        </script>
      </Helmet>

      {children}

    </>
  );
};