import { createApi } from '@reduxjs/toolkit/dist/query/react';

import { z3xBaseQuery } from 'app/utils/api.utils';
import { ISoft } from 'app/types/soft.interface';


export const softApi = createApi({
  reducerPath: 'softApi',
  baseQuery: z3xBaseQuery(),
  endpoints: (builder) => ({
    getSoft: builder.query<ISoft[], void>({
      query: () => ({
        url: '/softs'
      })
    })
  })
});

export const {
  useLazyGetSoftQuery
} = softApi;