import { FC, useMemo, useState } from 'react';

import { useAppSelector } from 'app/hooks/redux';
import { WithChildren } from 'app/types/withChildren';

export const RedirectAfterLogin: FC<WithChildren> = ({ children }) => {
  const { currentUser } = useAppSelector(state => state.user);

  const pathNames = useMemo(() =>
    ['/account/change-email', '/account/confirm-email']
  , []);

  const [redirectedUrl, setRedirectedUrl] = useState('');

  const [isRedirect, setIsRedirect] = useState(false);

  if (!currentUser && !isRedirect && pathNames.includes(window.location.pathname)) {
    setRedirectedUrl(window.location.href);
    setIsRedirect(true);
  }

  if (currentUser && isRedirect) {
    window.location.replace(redirectedUrl);
    return null;
  }

  return <>{children}</>;
};