import { FC, useEffect, useState } from 'react';
import { TokenResponse, useGoogleLogin } from '@react-oauth/google';
import { useNavigate } from 'react-router';

import { useSocialAuthMutation } from 'app/redux/api/user/user-socials.api';
import { SocialAuthDataType } from 'app/types/auth.interface';
import { useLazyUserQuery } from 'app/redux/api/user/user.api';
import { GoogleProvider } from 'app/providers/index';
import { toAbsoluteUrl } from 'metronic/helpers/AssetHelpers';

export const GoogleLogin: FC = () => (
  <GoogleProvider>
    <GoogleButton />
  </GoogleProvider>
);

export const GoogleButton: FC = () => {
  const navigate = useNavigate();

  const [requestSocialAuth] = useSocialAuthMutation();
  const [sendUserRequest] = useLazyUserQuery();

  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!error) {
      return;
    }
    const timeout = setTimeout(() => setError(''), 10000);
    return () => clearTimeout(timeout);
  }, [error]);

  const handleSocialAuthByGoogle = async (tokenResponse: Omit<TokenResponse, 'error' | 'error_description' | 'error_uri'>) => {
    const state: SocialAuthDataType = { type: 'google', ...tokenResponse };
    try {
      await requestSocialAuth(state).unwrap();
      await sendUserRequest().unwrap();
    } catch (e: any) {
      if (e.status === 412) {
        navigate('/auth/login/verifying', { state });
        return;
      }
      if (e.status === 406) {
        setError(e.data.error);
        return;
      }
      setError('Unknown error with Google.');
    } finally {
      setLoading(false);
    }
  };

  const googleLogin = useGoogleLogin({
    onSuccess: tokenResponse => handleSocialAuthByGoogle(tokenResponse),
    onError: () => {
      setError('Unknown error with Google.');
      setLoading(false);
    },
    onNonOAuthError: () => setLoading(false)
  });

  const handleGoogleLogin = () => {
    setLoading(true);
    googleLogin();
  };

  return (
    <div className="fv-row mb-5">
      <a
        className="btn btn-flex flex-center btn-light btn-lg w-100"
        onClick={() => handleGoogleLogin()}
      >
        <img
          alt="Logo"
          src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}
          className="h-20px me-3"
        />
        {!loading && 'Continue with Google'}
        {loading && (
          <span className="indicator-progress" style={{ display: 'block' }}>
					Please wait...
            <span className="spinner-border spinner-border-sm align-middle ms-2" />
          </span>
        )}
      </a>

      {error && (
        <div className="fv-plugins-message-container">
          <div className="fv-help-block">
            <span role="alert">{error}</span>
          </div>
        </div>
      )}
    </div>
  );
};