import { useEffect } from 'react';
import { Route, Routes, Outlet } from 'react-router-dom';

import { toAbsoluteUrl } from 'metronic/helpers';
import { Error500 } from './components/Error500';
import { Error404 } from './components/Error404';

const ErrorsLayout = () => {
  useEffect(() => {
    document.body.style.backgroundImage = 'none';
    return () => {
      document.body.style.backgroundImage = `url(${toAbsoluteUrl('/media/patterns/header-bg.jpg')})`;
    };
  }, []);

  return <Outlet />;
};

export const ErrorsPage = () => (
  <Routes>
    <Route element={<ErrorsLayout />}>
      <Route path="404" element={<Error404 />} />
      <Route path="500" element={<Error500 />} />
      <Route index element={<Error404 />} />
    </Route>
  </Routes>
);