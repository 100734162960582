import { FC, PropsWithChildren } from 'react';
import clsx from 'clsx';

type KTCardBodyProps = {
	className?: string
	scroll?: boolean
	height?: number
}

export const KTCardBody: FC<PropsWithChildren<KTCardBodyProps>> = (props) => {
  const { className, scroll, height, children } = props;
  return (
    <div
      className={clsx(
        'card-body',
        className && className,
        {
          'card-scroll': scroll,
        },
        height && `h-${height}px`
      )}
    >
      {children}
    </div>
  );
};