import { createApi } from '@reduxjs/toolkit/query/react';
import { ParsedQs } from 'qs';

import { RESET_PASSWORD_URL, VERIFY_EMAIL_URL } from 'app/utils/auth.utils';
import { setCurrentUser } from 'app/redux/slices/user/user.slice';
import { z3xBaseQuery } from 'app/utils/api.utils';

interface IEmailArgs {
  email: string;
}

interface ILoginArgs {
	username: string;
	password: string;
}

interface IRegisterArgs {
	email: string;
	username: string;
	plainPassword: string;
}

interface IResetPasswordArgs extends IEmailArgs {}

interface IResetArgs {
	token: string;
	newPassword: string;
}

interface IResendEmailConfirmationArgs extends IEmailArgs{}

interface IVerifyCodeArgs {
    code: string;
    hash: string;
}

interface ISentCodeArgs {
  hash: string;
}

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: z3xBaseQuery(),
  endpoints: (builder) => ({
    login: builder.mutation<null, ILoginArgs>({
      query: (args) => ({
        url: '/login',
        method: 'POST',
        body: args,
      }),
    }),
    register: builder.mutation<null, IRegisterArgs>({
      query: (args) => ({
        url: '/register',
        method: 'POST',
        body: {
          ...args,
          urlTo: VERIFY_EMAIL_URL()
        }
      })
    }),
    logout: builder.query<null, void>({
      query: () => ({
        url: '/logout',
        method: 'GET'
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(setCurrentUser(null));
        } catch (e) {
          dispatch(setCurrentUser(null));
        }
      }
    }),
    resendEmailConfirmation: builder.query<null, IResendEmailConfirmationArgs>({
      query: (args) => ({
        url: '/new-verify/email',
        method: 'POST',
        body: {
          ...args,
          urlTo: VERIFY_EMAIL_URL()
        }
      })
    }),
    verifyEmail: builder.query<null, ParsedQs>({
      query: (args) => ({
        url: '/verify/email',
        method: 'GET',
        params: args,
      })
    }),
    resetPassword: builder.mutation<null, IResetPasswordArgs>({
      query: (args) => ({
        url: '/reset-password',
        method: 'POST',
        body: {
          ...args,
          urlTo: RESET_PASSWORD_URL()
        }
      }),
    }),
    reset: builder.mutation<null, IResetArgs>({
      query: ({ token, newPassword }) => ({
        url: `/reset-password/reset/${token}`,
        method: 'POST',
        body: { newPassword }
      })
    }),
    verifyAddCode: builder.query<null, ISentCodeArgs>({
      query: (args) => ({
        url: '/user-verify-code/add/',
        method: 'POST',
        body: args
      }),
    }),
    verifyCode: builder.mutation<null, IVerifyCodeArgs>({
      query: (args) => ({
        url: '/user-verify-code/verify/',
        method: 'POST',
        body: args
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useRegisterMutation,
  useLazyResendEmailConfirmationQuery,
  useLazyVerifyEmailQuery,
  useLazyLogoutQuery,
  useResetPasswordMutation,
  useResetMutation,
  useLazyVerifyAddCodeQuery,
  useVerifyCodeMutation,
} = authApi;
