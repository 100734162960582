import { createApi } from '@reduxjs/toolkit/dist/query/react';

import { HydraApiResponse } from 'app/types/hydraData';
import { INotification } from 'app/types/notifications.interface';
import { z3xBaseQuery } from 'app/utils/api.utils';

interface IGetNotificationsArgs{
  page: number
  itemsPerPage: number
}

interface IGetNotificationsResponse {
  notifications: INotification[],
  total: number
}

interface IGetTotalUnreadNotificationsResponse {
  status: string
  count: number
}

export const notificationApi = createApi({
  reducerPath: 'notificationApi',
  baseQuery: z3xBaseQuery({ type: 'ld+json' }),
  tagTypes: ['HeaderNotifications'],
  endpoints: (builder) => ({
    getNotifications: builder.query<IGetNotificationsResponse, IGetNotificationsArgs>({
      query: (params) => ({
        url: '/notification-to-users',
        params: {
          ...params,
          'order[createdAt]': 'desc'
        }
      }),
      transformResponse: (response: HydraApiResponse<INotification[]>) => {
        return {
          notifications: response['hydra:member'],
          total: response['hydra:totalItems']
        };
      }
    }),
    getHeaderNotifications: builder.query<IGetNotificationsResponse, void>({
      query: () => ({
        url: '/notification-to-users',
        params: {
          page: 1,
          itemsPerPage: 5,
          'order[createdAt]': 'desc'
        },
      }),
      providesTags: ['HeaderNotifications'],
      transformResponse: (response: HydraApiResponse<INotification[]>) => {
        return {
          notifications: response['hydra:member'],
          total: response['hydra:totalItems']
        };
      }
    }),
    getTotalUnreadNotifications: builder.query<number, void>({
      query: () => ({
        url: '/notification/total'
      }),
      transformResponse: (response: IGetTotalUnreadNotificationsResponse) => {
        return response.count;
      },
      providesTags: ['HeaderNotifications']
    }),
    deleteNotifications: builder.mutation<null, number[]>({
      query: (ids) => ({
        url: '/notification/delete',
        method: 'POST',
        body: { ids }
      }),
      invalidatesTags: ['HeaderNotifications']
    }),
    readNotifications: builder.mutation<null, number[]>({
      query: (ids) => ({
        url: '/notification/read',
        method: 'POST',
        body: { ids }
      }),
      invalidatesTags: ['HeaderNotifications']
    })
  })
});

export const {
  useLazyGetNotificationsQuery,
  useGetHeaderNotificationsQuery,
  useGetTotalUnreadNotificationsQuery,
  useReadNotificationsMutation,
  useDeleteNotificationsMutation,
} = notificationApi;