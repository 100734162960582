import { BaseQueryFn, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { Mutex } from 'async-mutex';

import { setCurrentUser } from 'app/redux/slices/user/user.slice';
import { shellPort } from './common.utils';


export const baseUrl = process.env.REACT_APP_API_DEV;
export const baseShellUrl = () => `${process.env.REACT_APP_API_SHELL_DEV}:${shellPort()}`;

const mutex = new Mutex();

interface FetchBaseQueryArgs {
  url: 'z3x' | 'shell'
  type: 'json' | 'ld+json'
  withAutoLogout: boolean
}

export function z3xBaseQuery (args: Partial<FetchBaseQueryArgs> | void) {

  let defaultSettings: FetchBaseQueryArgs = { url: 'z3x', type: 'json', withAutoLogout: true };

  if (args) {
    defaultSettings = { ...defaultSettings, ...args };
  }

  const { url, type, withAutoLogout } = defaultSettings;

  const baseQuery = fetchBaseQuery({
    baseUrl: url === 'z3x' ? baseUrl : baseShellUrl(),
    prepareHeaders: (headers: Headers) => {
      headers.set('Accept', `application/${type}`);
      return headers;
    },
    ...(url === 'z3x') && { credentials: 'include' },
  });

  if (!withAutoLogout) {
    return baseQuery;
  }

  const baseQueryWithLogout: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError>
    = async (args, api, extraOptions) => {

      await mutex.waitForUnlock();

      const result = await baseQuery(args, api, extraOptions);

      if (result.error && result.error.status === 401) {
        if (!mutex.isLocked()) {
          const release = await mutex.acquire();

          await baseQuery({
            url: '/logout',
            credentials: 'include'
          }, api, extraOptions);

          api.dispatch(setCurrentUser(null));

          release();
          
        } else {
          await mutex.waitForUnlock();
        }
      }

      return result;
    };

  return baseQueryWithLogout;
}