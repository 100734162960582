import { createApi } from '@reduxjs/toolkit/dist/query/react';

import { ICard, ICardLogin, IProduct } from 'app/types/card.interface';
import { z3xBaseQuery } from 'app/utils/api.utils';
import { ICardLog } from 'app/types/card-logs.interface';


interface IValidateCardArgs {
	card: string;
	validationId: string;
}

interface IGetCardLogsResponse {
	status: string,
	data: ICardLog[]
}

interface IGetCardLogsArgs {
	id: number;
}

interface IGetUserCardLoginsArgs {
  cardId: number | undefined;
}

export const cardsApi = createApi({
  reducerPath: 'cardsApi',
  baseQuery: z3xBaseQuery(),
  endpoints: (builder) => ({
    getCards: builder.query<ICard[], void>({
      query: () => ({
        url: '/card-assigned-logs'
      })
    }),
    getProducts: builder.query<IProduct[], void>({
      query: () => ({
        url: '/products'
      })
    }),
    validateCard: builder.mutation<null, IValidateCardArgs>({
      query: ({ card, validationId }) => ({
        url: '/card/validate',
        method: 'POST',
        body: { card, validationId }
      })
    }),
    getCardLogs: builder.query<IGetCardLogsResponse, IGetCardLogsArgs>({
      query: ({ id }) => ({
        url: `/card/log/${id}`,
      })
    }),
    getUserCardLogins: builder.query<ICardLogin[], IGetUserCardLoginsArgs>({
      query: ({ cardId }) => ({
        url: '/user-crds',
        params: {
          card: cardId || ''
        }
      })
    })
  })
});

export const {
  useLazyGetCardsQuery,
  useLazyGetProductsQuery,
  useValidateCardMutation,
  useGetCardLogsQuery,
  useGetUserCardLoginsQuery
} = cardsApi;