import { FC } from 'react';

import { useLayout } from '../core';

export const Footer: FC = () => {
  const { classes } = useLayout();

  return (
    <div className="footer py-4 d-flex flex-lg-column" id="kt_footer">

      <div
        className={`${classes.footerContainer} d-flex flex-column flex-md-row align-items-center justify-content-between`}
      >
        <div className="text-dark order-2 order-md-1">
          <span className="text-muted fw-semibold me-2">{new Date().getFullYear()} &copy;</span>
          <a href="/" className="text-muted text-hover-primary">
						Z3X User Portal
          </a>
        </div>

        <ul className="menu menu-gray-500 menu-hover-primary order-1">
          <li className="menu-item">
            <a href="/policy" className="menu-link ps-0 pe-2">
							Policy
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};