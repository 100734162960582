import { createApi } from '@reduxjs/toolkit/dist/query/react';

import { z3xBaseQuery } from 'app/utils/api.utils';
import { IUserCrdLog } from 'app/types/credit.interface';

interface IGetUserCrdLogArgs {
  id: number
  params?: {
    page: number
    itemsPerPage: number
  }
}

export const creditApi = createApi({
  reducerPath: 'creditApi',
  baseQuery: z3xBaseQuery(),
  endpoints: (builder) => ({
    getUserCrdLogs: builder.query<IUserCrdLog[], IGetUserCrdLogArgs>({
      query: ({ id, params }) => ({
        url: `/user-crds/log/${id}`,
        params: {
          ...params,
          'order[date]': 'desc'
        }
      }),
    }),
  })
});

export const {
  useLazyGetUserCrdLogsQuery
} = creditApi;