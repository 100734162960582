import { createApi } from '@reduxjs/toolkit/query/react';

import { setCurrentUser } from 'app/redux/slices/user/user.slice';
import { z3xBaseQuery } from 'app/utils//api.utils';
import { IUserModel } from 'app/types/user.interface';

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: z3xBaseQuery(),
  endpoints: (builder) => ({
    user: builder.query<IUserModel, void>({
      query: () => '/me',
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setCurrentUser(data));
        } catch (err) {
          dispatch(setCurrentUser(null));
        }
      }
    }),
  }),
});

export const { useLazyUserQuery } = userApi;