import {
  FC,
  useState,
  useEffect,
} from 'react';
import { LayoutSplashScreen } from 'metronic/layout/core';

import { useLazyUserQuery } from 'app/redux/api/user/user.api';
import { WithChildren } from 'app/types/withChildren';


export const AuthInit: FC<WithChildren> = ({ children }) => {
  const [getCurrentUser] = useLazyUserQuery();

  const [showSplashScreen, setShowSplashScreen] = useState(true);

  const handleRequestUser = async () => {
    await getCurrentUser();
    setShowSplashScreen(false);
  };

  useEffect(() => {
    handleRequestUser();
  }, []);

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>;
};