import { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import * as qs from 'qs';

import { useLazyVerifyEmailQuery } from 'app/redux/api/auth/auth.api';
import { LayoutSplashScreen } from 'metronic/layout/core';
import { CustomMessage } from 'app/components/shared/CustomMessage';

export const VerifyEmail: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [showSplashScreen, setShowSplashScreen] = useState(true);
  const [error, setError] = useState<string>('');

  const [sendVerifyEmailRequest] = useLazyVerifyEmailQuery();

  useEffect(() => {
    const params = qs.parse(location.search.slice(1));

    const verifyEmail = async () => {
      try {
        await sendVerifyEmailRequest(params).unwrap();

        navigate('/auth/login', { state: { successVerify: true } });
      } catch (e: any) {
        setError(e?.data?.message || e?.data?.error || e?.data?.title || 'Something went wrong');
      } finally {
        setShowSplashScreen(false);
      }
    };

    if (Object.entries(params).length) {
      verifyEmail();
    } else {
      setShowSplashScreen(false);
    }
  }, []);

  return showSplashScreen ? <LayoutSplashScreen /> : (
    <CustomMessage
      logo={false}
      homeButton={true}
      footer={false}
    >
      <div className="text-center mb-2">
        <h1 className="fw-bold fs-2qx text-gray-800 mb-10">Error</h1>
        <div className="fw-semibold fs-3 text-muted mb-15">{error}</div>
        <Link to="/auth/resend-email-confirmation" className="btn btn-lg btn-primary fw-bold" style={ { minWidth: '165px' } }>
          Resend email
        </Link>
      </div>
    </CustomMessage>
  );
};