import { Navigate, Route, Routes } from 'react-router-dom';

import { AuthLayout } from 'app/components/layouts/AuthLayout';

import { SocialVerifying } from './login/socials-verifying/social-verifying';
import { ForgotPassword } from './forgotPassword/forgotPassword';
import { ResetPassword } from './resetPassword/resetPassword';
import { Registration } from './registration/registration';
import { VerifyEmail } from './verifyEmail/verifyEmail';
import { Login } from './login/login';
import { LoginAuthentication } from 'app/modules/auth/login/loginAuthentication/loginAuthentication';
import { ResendEmailConfirmation } from 'app/modules/auth/resendEmailConfirmation/resendEmailConfirmation';
import { useAppSelector } from 'app/hooks/redux';


export const AuthPage = () => {
  const hash2fa = useAppSelector(state => state.user.hash2fa);
  return (
    <Routes>
      <Route element={<AuthLayout />}>
        <Route path="login" element={<Login />} />
        <Route path="login/verifying" element={<SocialVerifying />} />
        <Route path="registration" element={<Registration />} />
        <Route path="resend-email-confirmation" element={<ResendEmailConfirmation />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="reset-password" element={<ResetPassword />} />
        <Route path="verify-email" element={<VerifyEmail />} />
        {hash2fa ? (
          <Route path="2fa" element={<LoginAuthentication />} />
        ) : (
          <Route path="2fa" element={<Navigate to="/auth/login" replace={true} />} />
        )}
        <Route index element={<Login />} />
      </Route>
    </Routes>
  );
};