import { createApi } from '@reduxjs/toolkit/query/react';

import { z3xBaseQuery } from 'app/utils/api.utils';
import { IThirdPartySoftwareToken } from 'app/types/thirdPartySoftwareTokens.interface';

interface IThirdPartySoftwareTokensCreate {
    userCrdId: number;
}

interface IThirdPartySoftwareTokensGet {
    id: number;
}

interface IThirdPartySoftwareTokensDelete {
  id: number;
}

interface IToggleThirdPartySoftwareTokens {
  id: number;
  active: boolean;
}

export const thirdPartySoftwareTokensApi = createApi({
  reducerPath: 'thirdPartySoftwareTokens',
  baseQuery: z3xBaseQuery(),
  endpoints: (builder) => ({
    getThirdPartySoftwareTokensForCrd: builder.query<IThirdPartySoftwareToken[], IThirdPartySoftwareTokensGet>({
      query: ( { id } ) => ({
        url: 'third-party-software-tokens',
        params: { userCrd: id }
      }),
    }),
    create: builder.mutation<IThirdPartySoftwareToken, IThirdPartySoftwareTokensCreate>({
      query: (body) => ({
        url: '/third-party-software-tokens',
        method: 'POST',
        body
      }),
    }),
    getThirdPartySoftwareTokens: builder.query<IThirdPartySoftwareToken, IThirdPartySoftwareTokensGet>({
      query: ( { id } ) => ({
        url: 'third-party-software-tokens/' + id,
      }),
    }),
    deleteThirdPartySoftwareTokens: builder.mutation<null, IThirdPartySoftwareTokensDelete>({
      query: ( { id } ) => ({
        url: 'third-party-software-tokens/' + id,
        method: 'DELETE',
      }),
    }),
    toggleThirdPartySoftwareTokens: builder.mutation<IThirdPartySoftwareToken, IToggleThirdPartySoftwareTokens>({
      query: ( { id, active } ) => ({
        url: '/third-party-software-tokens/' + id,
        method: 'PATCH',
        headers: { 'Content-Type': 'application/merge-patch+json' },
        body: JSON.stringify( { active })
      })
    }),
  }),
});

export const {
  useGetThirdPartySoftwareTokensForCrdQuery,
  useCreateMutation,
  useGetThirdPartySoftwareTokensQuery,
  useDeleteThirdPartySoftwareTokensMutation,
  useToggleThirdPartySoftwareTokensMutation
} = thirdPartySoftwareTokensApi;