export const SHELL_PORT_LC_KEY = 'shell_port';

export const checkReachableShellPort = async () => {
  let startPort = 20051;

  do {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_SHELL_DEV}:${startPort}/general/ping`);
      if (response.ok) {
        localStorage.setItem(SHELL_PORT_LC_KEY, startPort.toString());
        break;
      }
    } catch {
      startPort++;
    }
  } while (startPort < 20055);
};

export const shellPort = () => {
  const storagePort = localStorage.getItem(SHELL_PORT_LC_KEY);
  const defaultPort = '20051';

  return storagePort ?? defaultPort;
};