import { CurriedGetDefaultMiddleware } from '@reduxjs/toolkit/src/getDefaultMiddleware';
import { combineReducers } from 'redux';

import * as reducer from './slices';
import * as api from './api';

export const rootReducer = combineReducers({
  user: reducer.userReducer,
  shell: reducer.shellReducer,
  helperDownloads: reducer.helperDownloadsReducer,
  [api.authApi.reducerPath]: api.authApi.reducer,
  [api.userApi.reducerPath]: api.userApi.reducer,
  [api.userLogsApi.reducerPath]: api.userLogsApi.reducer,
  [api.profileApi.reducerPath]: api.profileApi.reducer,
  [api.userSocialsApi.reducerPath]: api.userSocialsApi.reducer,
  [api.signUpMethodsApi.reducerPath]: api.signUpMethodsApi.reducer,
  [api.shellApi.reducerPath]: api.shellApi.reducer,
  [api.cardsApi.reducerPath]: api.cardsApi.reducer,
  [api.cardDownloadsApi.reducerPath]: api.cardDownloadsApi.reducer,
  [api.creditApi.reducerPath]: api.creditApi.reducer,
  [api.fileApi.reducerPath]: api.fileApi.reducer,
  [api.firmwareApi.reducerPath]: api.firmwareApi.reducer,
  [api.softApi.reducerPath]: api.softApi.reducer,
  [api.notificationApi.reducerPath]: api.notificationApi.reducer,
  [api.supportApi.reducerPath]: api.supportApi.reducer,
  [api.forumApi.reducerPath]: api.forumApi.reducer,
  [api.conversationApi.reducerPath]: api.conversationApi.reducer,
  [api.thirdPartySoftwareTokensApi.reducerPath]: api.thirdPartySoftwareTokensApi.reducer,
  [api.subscriptionsApi.reducerPath]: api.subscriptionsApi.reducer,
  [api.unlinkCardFromCrdApi.reducerPath]: api.unlinkCardFromCrdApi.reducer,
  [api.userCrdApi.reducerPath]: api.userCrdApi.reducer,
  [api.userCrdLogApi.reducerPath]: api.userCrdLogApi.reducer,
  [api.userCrdSubscriptionApi.reducerPath]: api.userCrdSubscriptionApi.reducer
});

export const rootMiddleware = (getDefaultMiddleware: CurriedGetDefaultMiddleware) => {
  return getDefaultMiddleware()
    .concat(api.userApi.middleware)
    .concat(api.authApi.middleware)
    .concat(api.profileApi.middleware)
    .concat(api.userLogsApi.middleware)
    .concat(api.userSocialsApi.middleware)
    .concat(api.signUpMethodsApi.middleware)
    .concat(api.shellApi.middleware)
    .concat(api.cardsApi.middleware)
    .concat(api.creditApi.middleware)
    .concat(api.cardDownloadsApi.middleware)
    .concat(api.fileApi.middleware)
    .concat(api.firmwareApi.middleware)
    .concat(api.softApi.middleware)
    .concat(api.notificationApi.middleware)
    .concat(api.supportApi.middleware)
    .concat(api.forumApi.middleware)
    .concat(api.conversationApi.middleware);
};
