import { useLayout } from 'metronic/layout/core';
import { Toolbar1 } from './Toolbar1';

export const Toolbar = () => {
  const { config } = useLayout();

  switch (config.toolbar.layout) {
  case 'toolbar1':
    return <Toolbar1 />;

  default:
    return <Toolbar1 />;
  }
};