import { createApi } from '@reduxjs/toolkit/dist/query/react';

import { z3xSupportQuery } from 'app/utils/support.utils';
import {
  IGetSupportCategoriesResponse,
  ISearchSupportArticlesResponse,
  ISubmitSupportTicketResponse,
  IUploadSupportFileResponse
} from 'app/types/support.interface';


export const supportApi = createApi({
  reducerPath: 'supportApi',
  baseQuery: z3xSupportQuery(),
  endpoints: (builder) => ({
    getSupportCategories: builder.query<IGetSupportCategoriesResponse, void>({
      query: () => ({
        url: '/kb/get-categories',
      }),
    }),
    searchSupportArticles: builder.mutation<ISearchSupportArticlesResponse, FormData>({
      query: (body) => ({
        url: '/kb/search',
        method: 'POST',
        body
      })
    }),
    uploadSupportFile: builder.mutation<IUploadSupportFileResponse, FormData>({
      query: (body) => ({
        url: '/eup/upload',
        method: 'POST',
        body
      })
    }),
    submitSupportTicket: builder.mutation<ISubmitSupportTicketResponse, FormData>({
      query: (body) => ({
        url: '/eup/submit-ticket',
        method: 'POST',
        body
      })
    })
  })
});

export const {
  useGetSupportCategoriesQuery,
  useSearchSupportArticlesMutation,
  useUploadSupportFileMutation,
  useSubmitSupportTicketMutation
} = supportApi;