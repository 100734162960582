import clsx from 'clsx';

import { DefaultTitle } from '../header/page-title/DefaultTitle';
import { useLayout } from 'metronic/layout/core';

export const Toolbar1 = () => {
  const { classes } = useLayout();

  return (
    <>
      <div className="toolbar py-5 py-lg-15" id="kt_toolbar">
        <div
          id="kt_toolbar_container"
          className={clsx(classes.toolbarContainer.join(' '), 'd-flex flex-stack')}
        >
          <DefaultTitle />

        </div>
      </div>
    </>
  );
};