import { createApi } from '@reduxjs/toolkit/dist/query/react';

import { collectDuplicatedSoftIntoModels } from 'app/utils/firmware.utils';
import { IModel, IModelTransformed } from 'app/types/firmware.interface';
import { z3xBaseQuery } from 'app/utils/api.utils';

interface IGetBrandsResponse {
	brands: {
		brand: string
	} [];
}

interface IGetModelsByBrandArgs {
	brand: string;
}

interface ISearchModelsArgs {
	search: string;
}

export const firmwareApi = createApi({
  reducerPath: 'firmwareApi',
  baseQuery: z3xBaseQuery(),
  endpoints: (builder) => ({
    getBrands: builder.query<string[], void>({
      query: () => ({
        url: '/model/brands'
      }),
      transformResponse: (response: IGetBrandsResponse) => {
        return response.brands.map(item => item.brand).sort((a, b) => a > b ? 1 : -1);
      }
    }),
    getModelsByBrand: builder.query<IModelTransformed[], IGetModelsByBrandArgs>({
      query: ({ brand }) => ({
        url: '/models',
        params: { brand }
      }),
      transformResponse: (response: IModel[]) => {
        return collectDuplicatedSoftIntoModels(response);
      }
    }),
    searchModels: builder.query<IModelTransformed[], ISearchModelsArgs>({
      query: ({ search }) => ({
        url: '/models',
        params: { model: search }
      }),
      transformResponse: (response: IModel[]) => {
        return collectDuplicatedSoftIntoModels(response);
      }
    })
  })
});

export const {
  useGetBrandsQuery,
  useLazyGetModelsByBrandQuery,
  useLazySearchModelsQuery,
} = firmwareApi;