import { FC } from 'react';
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom';

import { Logout, AuthPage } from '../modules/auth';
import { PrivateRoutes } from './PrivateRoutes';
import { HomeRoutes } from 'app/pages/home/HomeRoutes';
import { InfoLayout } from '../components/layouts/InfoLayout';
import { ErrorsPage } from '../pages/errors/ErrorsPage';
import { PolicyPage } from '../pages/policy/PolicyPage';
import { App } from '../App';
import { useAppSelector } from 'app/hooks/redux';

export const AppRoutes: FC = () => {
  const { currentUser } = useAppSelector(state => state.user);
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<App />}>
          <Route path="error/*" element={<ErrorsPage />} />
          <Route path="logout" element={<Logout />} />
          {currentUser ? (
            <>
              <Route path="/*" element={<PrivateRoutes />} />
              <Route index element={<Navigate to="/cards" replace={true} />} />
            </>
          ) : (
            <>
              <Route path="/" element={<HomeRoutes/>} />
              <Route path="auth/*" element={<AuthPage />} />
              <Route path="*" element={<Navigate to="/auth" replace={true} />} />
            </>
          )}
          <Route element={<InfoLayout />}>
            <Route path="/policy" element={<PolicyPage />} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
};