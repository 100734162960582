import { fetchBaseQuery } from '@reduxjs/toolkit/query';

const baseSupportUrl = process.env.REACT_APP_SUPPORT_URL;

export const z3xSupportQuery = () => {
  return fetchBaseQuery({
    baseUrl: baseSupportUrl,
    credentials: 'include',
    mode: 'cors'
  });
}; 