import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IProfileModel, IUserModel } from 'app/types/user.interface';

interface IUserState {
	currentUser: IUserModel | null;
    hash2fa: string | null
}

const initialState: IUserState = {
  currentUser: null,
  hash2fa: null
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setCurrentUser: (state, action: PayloadAction<IUserModel | null>) => {
      state.currentUser = action.payload;
    },
    setHash2fa: (state, action: PayloadAction<string | null>) => {
      state.hash2fa = action.payload;
    },
    setUpdateUser2faProp : (state, action: PayloadAction<boolean> ) => {
      if (state.currentUser) {
        state.currentUser.userPortal2fa = action.payload;
      }
    },
    setProfile: (state, action: PayloadAction<IProfileModel>) => {
      if (state.currentUser) {
        state.currentUser.profile = action.payload;
      }
    }
  }
});

export const {
  setCurrentUser,
  setHash2fa,
  setUpdateUser2faProp,
  setProfile
} = userSlice.actions;

export default userSlice.reducer;