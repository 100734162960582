import { FC } from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';

import { WithChildren } from 'app/types/withChildren';

export const GoogleProvider: FC<WithChildren> = ({ children }) => {

  if (!process.env.REACT_APP_AUTH_GOOGLE_ID) {
    return null;
  }

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_AUTH_GOOGLE_ID as string}>
      {children}
    </GoogleOAuthProvider>
  );
};