import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { toAbsoluteUrl } from 'metronic/helpers';
import { Logo } from '../shared/logo/Logo';

export const AuthLayout = () => {

  useEffect(() => {
    document.body.style.backgroundImage = 'none';
    return () => {};
  }, []);

  return (
    <div
      className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed"
      style={{
        backgroundImage: `url(${toAbsoluteUrl('/media/illustrations/sketchy-1/14.png')})`,
      }}
    >
      <div className="d-flex flex-center flex-column flex-column-fluid p-5 p-md-10 pb-lg-20">
        <Logo />

        <div className="w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto">
          <Outlet />
        </div>
      </div>
    </div>
  );
};