import { FC, useEffect, useRef } from 'react';

import { SHELL_PROTOCOL } from 'app/utils/shell.utils';
import { useAppSelector } from 'app/hooks/redux';
import { WithChildren } from 'app/types/withChildren';


export const FirstShellLaunchConfirmation: FC<WithChildren> = ({ children }) => {
  const shellStatus = useAppSelector(state => state.shell.status);

  const isFirstRun = useRef(true);

  useEffect(() => {
    // Opening the app via the custom protocol below disables updates after changes in react development mode
    if (process.env.NODE_ENV === 'development') {
      return;
    }

	  if (!isFirstRun.current) {
		  return;
	  }

	  if (shellStatus !== 'no connection') {
      return;
	  }

    setTimeout(() => {
      isFirstRun.current = false;
      window.location.href = SHELL_PROTOCOL;
    }, 2000);
    
  }, [shellStatus]);
	
  return (
    <>
      {children}
    </>
  );
};