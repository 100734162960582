import { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import * as qs from 'qs';

import { useChangeNewEmailMutation } from 'app/redux/api/user/sign-up-methods.api';
import { LayoutSplashScreen } from 'metronic/layout/core';
import { useLazyUserQuery } from 'app/redux/api/user/user.api';
import { CustomMessage } from 'app/components/shared/CustomMessage';

export const ConfirmNewEmail: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [showSplashScreen, setShowSplashScreen] = useState(true);

  const [requestChangeNewEmail] = useChangeNewEmailMutation();
  const [sendUserRequest] = useLazyUserQuery();

  useEffect(() => {
    const params = qs.parse(location.search.slice(1));

    if (!params.token) {
      navigate('/error/500');
    }

    (async () => {
      try {
        const token = String(params.token);
        await requestChangeNewEmail(token).unwrap();
        await sendUserRequest().unwrap();
      } catch (e) {
        navigate('/error/500');
      } finally {
        setShowSplashScreen(false);
      }
    })();
  }, []);

  if (showSplashScreen) return <LayoutSplashScreen />;

  return (
    <CustomMessage
      header="Success"
      text="You have successfully changed your email address"
      footer={false}
    />
  );
};