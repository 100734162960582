import { IModel, IModelTransformed } from '../types/firmware.interface';

export const collectDuplicatedSoftIntoModels = (models: IModel[]): IModelTransformed[] => {
  const uniqueModels: IModelTransformed[] = [];

  models.forEach(item => {
    const findModelIndex = uniqueModels.findIndex(elem => elem.model === item.model);

    if (findModelIndex === -1) {
      uniqueModels.push({
        ...item,
        soft: [item.soft]
      });
      return;
    }

    const findSoft = uniqueModels[findModelIndex].soft.find(soft => soft.name === item.soft.name);

    if (!findSoft) {
      uniqueModels[findModelIndex].soft.push(item.soft);
    }
  });

  return uniqueModels.sort((a, b) => a.model > b.model ? 1 : -1);
};