import { FC } from 'react';

import { AuthInit, RedirectAfterLogin } from '../hocs/app';
import { WithChildren } from '../types/withChildren';


export const AuthProvider: FC<WithChildren> = ({ children }) => {
  return (
    <AuthInit>
      <RedirectAfterLogin>
        {children}
      </RedirectAfterLogin>
    </AuthInit>
  );
};